import { useSelector } from 'react-redux';

import styles from './styles.module.css';
import images from '@images';

function CenterPin(){

  return (
    <div className={styles.container}>
      <img src={images.appPin} alt="App pin" className={styles.pin}/>
    </div>
  );
}

export default CenterPin;
