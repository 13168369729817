import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { toggleMenu } from '@containers/Menu/slice';
import { toggleLanguageModal } from '@containers/LanguageInput/slice';

import IconSVG from '@components/IconSVG';

import { getMobileOperatingSystem } from '@utils/common';

import classes from './styles.module.css';

const Menu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const platform = getMobileOperatingSystem();

  const { isVisible } = useSelector(state => state.menu);

  const handleMenuToggle = () => dispatch(toggleMenu());

  const handleAppDownload = () => {
    const storeUrl = platform === 'iOS'
    ? 'https://apps.apple.com/ba/app/linky-parking/id1641288061' 
    : 'https://play.google.com/store/apps/details?id=com.linky.app';
    window.open(storeUrl, "_blank");
  }

  const handleChangeLanguage = ()  => {
    dispatch(toggleMenu());
    dispatch(toggleLanguageModal());
  }

  return <div className={`${classes.container} ${isVisible ? '' + classes.visible : classes.hidden}`}>
    <div className={classes.menuItem} onClick={handleChangeLanguage}>
      <IconSVG name={'globe'} fill="white" className={classes.icon}/>
      <div className={classes.text}>{t('Language')}</div>
    </div>
    <div className={classes.menuItem} onClick={handleAppDownload}>
      <IconSVG name={platform === 'iOS' ? 'appStore' : 'playStore'} fill="white" className={classes.icon}/>
      <div className={classes.text}>{t('Download app')}</div>
    </div>
    <div className={`${classes.menuItem} ${classes.centered}`} onClick={handleMenuToggle}>
      <IconSVG name={'arrowRight'} fill="white" className={classes.icon}/>
    </div>
  </div>;
}

export default Menu;
