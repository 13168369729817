function formValidation({ password, confirmPassword }) {
  let errors = {};

  let oneNumberRegex = /(?=.*?\d)/;
  let eightCharsLongRegex = /^.{8,}$/;
  let oneUppercaseLetterRegex = /(?=.*?[A-Z])/;

  if(!password){
    errors.password = "Required";
  }
  if(!oneNumberRegex.test(password)){
    errors.password = "Password must contain at least one number";
  }
  if(!eightCharsLongRegex.test(password)){
    errors.password = "Password should be minimum 8 characters";
  }
  if(!oneUppercaseLetterRegex.test(password)){
    errors.password = "Password must contain one uppercase letter";
  }

  if(
    password &&
    (password !== confirmPassword)
  ){
    errors.confirmPassword = "Passwords are not mathing";
  }

  return errors;
}

export default formValidation;