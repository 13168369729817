import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Field, Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';
import TextInput from '@components/TextInput';
import formValidation from './formValidation';

import Button from '@components/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DownloadPdfButton from './DownloadPdfButton';

import useReceipt from '../useReceipt';
import { storage } from '@utils/common';

import classes from '../styles.module.css';

const ReceiptActionForm = () => {
  const [emailFieldVisible, setEmailFieldVisible] = useState();
  const { sendToEmail } = useReceipt();
  const { t } = useTranslation();

  const parkingTicket = useSelector(state => state.parkingTicket);
  const { emailSent, isEmailSending } = parkingTicket;

  const handleSendToEmail = ({ email }) => {
    if (!emailFieldVisible) {
      setEmailFieldVisible(true);
    }
    else {
      sendToEmail(email, parkingTicket.sessionTag);
    }
  }

  const renderEmailField = () => (
    emailFieldVisible && !emailSent ?
      <>
        <div className={classes.divider}></div>
        <Field
          name="email"
          component={TextInput}
          placeholder={t('Email address')} />
      </> : null
  );

  const renderButtonTitle = () => {
    let title = t('Send to email');
    if (emailFieldVisible) {
      title = emailSent ? t('Receipt sent') : t('Confirm and send');
    }
    return title;
  }

  const handleValidation = values => emailFieldVisible ? formValidation(values) : null;

  // TODO - replace with redux persist
  const lastKnownInfo = JSON.parse(storage.get('lastKnownInfo'));
  const initialValues = { email: lastKnownInfo?.email || '' }

  return (
    <Formik
      onSubmit={handleSendToEmail}
      validate={handleValidation}
      initialValues={initialValues}>
      {({ isValid }) => (
        <Form>
          {renderEmailField()}
          <Row>
            <Col className="col-2">
              <DownloadPdfButton parkingTicket={parkingTicket} />
            </Col>
            <Col className="col-10">
              <div className="d-grid gap-2">
                <Button text={renderButtonTitle()}
                  className="btn-block"
                  type="submit"                  
                  disabled={emailSent || isEmailSending || !isValid}
                />
              </div>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default ReceiptActionForm;
