// Custom components
import AreaItem from './AreaItem';

function MultipleAreaSelect(props) {

  /** Props */
  const { selectedArea } = props;
  
  /** GUARD */
  if(!selectedArea.isGroup){
    return <div>Selected area is not a group.</div>
  }
  
  return (
    <div>
      {selectedArea.parkingsInGroup.map((area) => {
        return (<AreaItem key={area.parkingId} areaData={area}/>);
      })}
    </div>
  );
}

export default MultipleAreaSelect;
