import { createAsyncThunk } from '@reduxjs/toolkit';

/** Endpoints */
import GetAreaDetailsByCode from '@endpoints/Parking/GetAreaDetailsByCode';
import { default as GetSessionDetailsByTag } from '@endpoints/Session/GetDetailsByTag';

/**
  * @function
  * @async
  * @desc This async action will fetch session(ticket) details using sessionTag.
  */
const getSessionDetails = createAsyncThunk(
  'parkingTicket/getSessionDetails',
  async (sessionTag, thunkAPI) => {

    let queryFilter = { sessionTag };

    try {
      let sessionResult = await GetSessionDetailsByTag.get(queryFilter);

      /** Get information about parking area that is used and set it on redux state */
      let parkingId = sessionResult.data.parkingId;
      let parkingResult = await GetAreaDetailsByCode.get({ parkingId });
      let parkingArea = {
        ...parkingResult.data,
        centerPoint: JSON.parse(parkingResult.data.centerPoint)
      };

      return {
        parkingArea,
        ...sessionResult.data
      };

    } catch (error) {
      console.log("Error parkingTicket/getChargeDetails");
      console.log(error);
    }
  }
);

export default getSessionDetails;
