// Number of minutes in unit
export const minutesIn = {
  minutes: 1,
  hours: 60,
  days: 1440,
  weeks: 10080,
  months: 43200,
};

// Pricing type IDs mapping object
export const pricingTypeId = {
  STANDARD: 1,
  DAYS: 2,
  MONTHS: 3,
  WEEKS: 4,
  CONTINUOUS: 5,
};

// Pricing unit config object
export const pricingUnitCfg = {
  [pricingTypeId.STANDARD]: {
    label: "time",
    units: [
      { name: "hours", min: 0, max: 24 },
      { name: "minutes", min: 0, max: 59 },
    ],
  },
  [pricingTypeId.CONTINUOUS]: {
    label: "time",
    units: [
      { name: "hours", min: 0, max: 24 },
      { name: "minutes", min: 0, max: 59 },
    ],
  },
  [pricingTypeId.DAYS]: {
    label: "days",
    units: [{ name: "days", min: 1, max: 7 }],
  },
  [pricingTypeId.WEEKS]: {
    label: "weeks",
    units: [{ name: "weeks", min: 1, max: 4 }],
  },
  [pricingTypeId.MONTHS]: {
    label: "months",
    units: [{ name: "months", min: 1, max: 12 }],
  },
};

/** Type of parking managment */
export const PARKING_MANAGEMENT_TYPES = {
  MANUAL: 'Manual',
  CAMERA: 'Camera'
}; 