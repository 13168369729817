import moment from 'moment';
import { createAsyncThunk } from '@reduxjs/toolkit';

/** Endpoints */
import Stop from '@endpoints/Session/Stop';

/**
  * @function
  * @async
  * @desc
  * @NOTE: we are subtracting few seconds of enddate just to be able to render out stopped ticket without relad.
  */
const stopParking = createAsyncThunk(
  'parkingInput/stopParking',
  async ({ sessionTag }, thunkAPI) => {

    const body = { sessionTag };

    try {
      let result = await Stop.post(body);

      return {
        endDatetime: moment(result.data.endDatetime).subtract(5, 'seconds').format()
      };
    } catch (error) {
      console.log("Error parkingInput/stopParking");
      console.log(error);
    }
  }
);

export default stopParking;
