import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import styles from './styles.module.css';

/**
  * @function
  * @desc Component to render out button based on bootstrap button.
  * For more info visit: https://react-bootstrap.github.io/components/buttons/
  * NOTE: Size param is added as class since we use custom class name
  */
function StyledButton(props){

  let {
    text,
    size,
    variant,
    onClick,
    loading,
    children,
    className,
    transparent,
    ...rest
  } = props;

  let bsPrefix = `btn btn-${size} ${styles[variant]} ${className}`;

  function renderContent(){
    if(loading){
      return <Spinner animation="border" variant="light" size="sm"/>
    }

    return (<>{text || children}</>);
  }

  return (
    <Button
      onClick={onClick}
      bsPrefix={bsPrefix}
      {...rest}>

      {renderContent()}

    </Button>
  );
}

StyledButton.defaultProps = {
  size: 'lg',
  text: null,
  className: '',
  onClick: null,
  loading: false,
  variant: 'primary',
  transparent: false
};

export default StyledButton;
