import jsPDF from "jspdf";

const generatePdf = ({ receipt, receiptNumber, nameCode, info, footer }, t) => {
  // Generate PDF
  const doc = new jsPDF("p", "pt", "a4");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  // Logo
  doc.addImage("logoLinky.jpg", "PNG", pageWidth / 2 - 83, 50, 166, 50);
  // Receipt number
  doc.setTextColor("#8C8C8C");
  doc.setFontSize(14);
  doc.text(`${receipt + receiptNumber}`, pageWidth / 2, 140, "center");
  // Divider
  doc.setDrawColor("#EF827F");
  doc.line(100, 170, pageWidth - 100, 170);
  let posY = 200;
  // Section
  info.forEach(({ section, data }) => {
    // Label
    doc.setFontSize(11);
    doc.setTextColor("#2F3041");
    doc.text(section, 110, posY);
    posY = posY + 30;
    // Data
    data.forEach(([label, value, style = "normal"]) => {
      // Skip rows with empty values i.e. user email
      if (!value) return;
      // label
      doc.setTextColor("#8C8C8C");
      doc.setFont(undefined, style).text(label, 120, posY);
      // value
      doc.setTextColor("#2F3041");
      doc.text(value, 250, posY);
      posY = posY + 20;
    });
    posY = posY + 10;
    // Divider
    doc.setDrawColor("#EF827F");
    doc.line(100, posY, pageWidth - 100, posY);
    posY = posY + 30;
  });
  // Footer
  // Divider
  doc.setDrawColor("#EF827F");
  doc.line(0, pageHeight - 40, pageWidth, pageHeight - 40);
  doc.setTextColor("#EF827F");
  doc.setFontSize(8);
  doc
    .setFont(undefined, "normal")
    .text(footer, pageWidth / 2, pageHeight - 18, "center");

  doc.save(`${t("Parking receipt")} - ${nameCode} - ${receiptNumber}.pdf`);
};

export default generatePdf;
