import { useState } from 'react';
import { t } from 'i18next';

import IconSVG from '@components/IconSVG';

import classes from './styles.module.css';

const Dropdown = ({ options, placeholder, field, form: { setFieldValue }, className }) => {

  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options.find(({ value }) => value === field.value)
    || { value: null, title: placeholder || t('Please select an option') });

  const generateOption = ({ value, icon, title }) => {
    return <>
      {icon && <div className={classes.icon}>{icon}</div>}
      <div className={`${classes.title} ${!value && classes.placeholder}`}>{title}</div>
    </>;
  }

  const handleSelectOption = option => {
    setFieldValue(field.name, option.value);
    setSelectedOption(option);
    setIsExpanded(false);
  }

  const handleButtonClick = () => setIsExpanded(!isExpanded);

  return (
    <div className={`${classes.container} mb-3 ${className}`}>
      {/* Label */}
      <div className={classes.label}>{t('Language')}</div>

      {/* Button */}
      <button className={`btn ${classes.button} ${classes.item} ${classes.selected} ${isExpanded && classes.expanded}`}
        onClick={handleButtonClick}
        type="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false">

        {/* Selected option */}
        {generateOption(selectedOption)}

        {/* Chevron */}
        <div className={classes.chevronWrapper}><IconSVG name={'chevron'} className={`${classes.chevronIcon} ${isExpanded ? classes.chevronIconUp : classes.chevronIconDown}`} /></div>
      </button>

      {/* Option list */}
      {isExpanded &&
        <div className={classes.list}>          
          {options.filter(({ value }) => value !== selectedOption.value)
            .map(option =>
              <div key={option.value}
                className={classes.item}
                onClick={() => handleSelectOption(option)}>
                {generateOption(option)}
              </div>
            )
          }
        </div>
      }
    </div>
  );
}

export default Dropdown;