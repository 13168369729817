const countryCodeMap = {
  GB: 'en-GB',
  SV: 'sv-SE'
}

export const formatAmount = (amount, currency, countryCode = 'SV') => {    
  return new Intl.NumberFormat(
    countryCodeMap[countryCode], 
    { style: 'currency', currency }
  ).format(amount);
}