import { resolve } from "@sentry/utils";

/**
  * @class
  * @name GPS
  * @desc Entry class for all GPS activities
  */
class GPS {

  getCurrentLocation(){          
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });
  }
}

export default new GPS();
