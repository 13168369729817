import * as Sentry from "@sentry/react";
import { storage } from '@utils/common';

class Logger {

  constructor(){
    // Get user data        
    const lastKnownInfo = storage.get('lastKnownInfo');
    
    if(lastKnownInfo){
      this.setUser(JSON.parse(lastKnownInfo));
    }
  }

  setUser = user => {
    // Set user data    
    Sentry.setUser(user);
  }

  setAction = action => {
    Sentry.setTag("Action", action);
  }

  log = (data, level = "error") => {
    // Debug messages should only be available on DEV environment
    if(process.env.REACT_APP_ENV !== 'dev' && level === "debug") return null;

    // Generate messages & context data
    const message = data?.message || data.toString();
    const context = data?.context || data;  

    // Set type of log entry (debug, warning, error, info,...)
    Sentry.configureScope(scope => scope.setLevel(level));
    
    // Set context structured data
    if(typeof context === 'object'){
      for (const [key, value] of Object.entries(context)) {
        Sentry.setContext(key, value);
      }
    }
    
    // Insert log record
    Sentry.captureException(new Error(message));      
  }
  
}

export default new Logger();