import { current, createSlice } from '@reduxjs/toolkit';

import getParkings from './asyncThunks/getParkings';
import getGooglePlaces from './asyncThunks/getGooglePlaces';

const initialState = {
  parkings: [],
  googlePlaces: [],
  searchQuery: null,
  isParkingsLoading: false,
  isGooglePlacesLoading: false
}

export const keywordSearchSlice = createSlice({
  name: 'keywordSearch',
  initialState,
  reducers: {
    setQuery: (state, action) => {
      return { ...initialState, searchQuery: action.payload };
    },
    reset: () => {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(getParkings.pending, (state) => {
      state.isParkingsLoading = true;
    });
    builder.addCase(getParkings.fulfilled, (state, action) => {
      state.parkings = action.payload;
      state.isParkingsLoading = false;
    });
    builder.addCase(getParkings.rejected, (state) => {
      state.parkings = [];
      state.isParkingsLoading = true;
    });
    builder.addCase(getGooglePlaces.pending, (state) => {
      state.isGooglePlacesLoading = true;
    });
    builder.addCase(getGooglePlaces.fulfilled, (state, action) => {
      state.googlePlaces = action.payload;
      state.isGooglePlacesLoading = false;
    });
    builder.addCase(getGooglePlaces.rejected, (state) => {
      state.googlePlaces = [];
      state.isGooglePlacesLoading = false;
    });
  }
})

/** Actions */
const { setQuery, reset } = keywordSearchSlice.actions;

/** Async Actions */
export { getParkings, getGooglePlaces, setQuery, reset };

/** Reducer */
export default keywordSearchSlice.reducer;