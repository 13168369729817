import _ from 'lodash';
import { useState, useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import Col from 'react-bootstrap/Col';
import Button from '@components/Button';

import images from '../../assets/images';

import classes from './styles.module.css';
import moment from 'moment';

const PaymentProviderButton = ({ onSubmit, price, currency }) => {

  const { t } = useTranslation();
  const stripe = useStripe();

  const [paymentRequest, setPaymentRequest] = useState(null);
  const [paymentProvider, setPaymentProvider] = useState(null);

  const selectedArea = useSelector( state => state.main.selectedArea);

  const handleOnClick = () => paymentRequest.show();

  /**
    * @desc Method will run to check if user has google or apple pay and show button for it.
    */
  useEffect(() => {
    if(!stripe) return null;
    
    /* Check if payment method is available in browser wallet and show Payment summary to user (GPay only) */
    const pr = stripe.paymentRequest({
      country: 'US',
      currency: currency.toLowerCase(),
      total: {
        label: t('Payment for parking session'),
        amount: _.round(price * 100, 2),
      },
      requestPayerName: true,
      requestPayerEmail: true,
    });

    // Check the availability of the Payment Request API.
    pr.canMakePayment().then(result => {
      if (result) {
        const { applePay, googlePay } = result;
        setPaymentProvider(applePay ? 'Apple' : googlePay ? 'Google' : null);
        setPaymentRequest(pr);
      }
    });

    pr.on('paymentmethod', async ({ paymentMethod }) => onSubmit(paymentMethod.id));
  }, [stripe]);


  return !paymentProvider ? null :
  <Col xs={4}>
    <Button
      variant='secondaryTransparent'
      className={`${classes.button} ${classes.paymentProviderBtn}`}
      onClick={handleOnClick}>
      <img src={images[`logo${paymentProvider}`]} className={classes.btnImage} alt={`logo ${paymentProvider} pay`} /> {t('Pay')}
    </Button>
  </Col>;
}

export default PaymentProviderButton;
