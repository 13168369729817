import { createAsyncThunk } from '@reduxjs/toolkit';

import { delay } from '@utils/common';

/** Endpoints */
import { default as GetChargeDetailsById} from '@endpoints/Charge/GetDetailsById';
import { default as GetSessionDetailsByChargeId } from '@endpoints/Session/GetDetailsByChargeId';

/**
  * @function
  * @async
  * @desc This async thunk will take chargeId and check status it:
  * PENDING - charge is stil not resolved so we keep loop alive with 3seconds delay
  * SUCCESS - charge went ok redirect to ticket
  * ERROR -  something went wrong with payment
  */
const confirmParking = createAsyncThunk(
  'parkingInput/confirmParking',
  async (reqData, thunkAPI) => {

    const { chargeId } = reqData;

    let result = {};
    let chargeStatus = 'PENDING';
    let queryFilter = { chargeId };

    while(chargeStatus === 'PENDING'){

      await delay(3000);

      try {
        result = await GetChargeDetailsById.get(queryFilter);
        chargeStatus = result.data.status;

      } catch (error) {
        console.log("Error parkingInput/confirmParking");
        console.log(error);
      }
    }

    if(chargeStatus === 'SUCCESS'){

      try {
        result = await GetSessionDetailsByChargeId.get(queryFilter);
        let { sessionTag } = result.data;

        /** Navigate to main screen and include sid */
        document.location.replace(`${document.location.origin}/?sessionTag=${sessionTag}`);

      } catch (error) {
        console.log("Error parkingTicket/getSessionByChargeId");
        console.log(error);
      }
    } else if(chargeStatus === 'FAILED'){
      return { error: result.data.message  }
    }
  }
);

export default confirmParking;
