import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Intro from '@components/Intro';

import { extractCoordinates } from '@utils/common';

function ParkingAreaCodeQueryRoute(){

  let navigate = useNavigate();
  let { parkingAreaCode } = useParams();

  useEffect(() => {
    getAreaDetails();
  }, [ parkingAreaCode ]);

  async function getAreaDetails(){
    const host = process.env.REACT_APP_API_HOST;
    const url = `${host}/Parking/GetAreaDetailsByCode?parkingAreaCode=${parkingAreaCode}`;

    try {
      let request = await fetch(url);
      let result  = await request.json();

      let { lat, lng } = extractCoordinates(JSON.parse(result.data.centerPoint));
      navigate(`/?lat=${lat}&lng=${lng}`, { replace: true });
    } catch (error) {
      console.log("Intro error");
      console.log(error);
    }
  }

  return (
    <Intro />
  );
}

export default ParkingAreaCodeQueryRoute;
