// Import Styles
import styles from './styles.module.css'

/**
  * @desc Simple component to display zone code in box
  */
function ZoneCode({ color, children }) {

  const className = `${styles.container} ${styles[color]}`;

  return (
    <div className={className}>
      {children}
    </div>
  );
}

ZoneCode.defaultProps = {
  color: 'white'
};

export default ZoneCode;
