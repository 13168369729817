/** Turf library */
import area from '@turf/area';
import intersect from '@turf/intersect';

const turf = {
  area,
  intersect
};


/**
 * @function
 * @description Function will take areas, check if there area areas that are overlaping, if they do they will be grouped.
 * And also data will be formated.
 */
const groupAndFormatAreaData = (parkingAreas) => {
  let finalAreas = [];  
  let alreadyComparedAreasIds = [];

  for(let i=0; i<parkingAreas.length; i++){
    let parkingArea = parkingAreas[i];
    let parkingAreasThatOverlaps = [];
    let parkingAreaPolygon = JSON.parse(parkingArea.locationPolygon);

    /** If polygon is actualy a point add it to array and skip */
    if(parkingAreaPolygon.type === 'Point'){
      finalAreas.push({
        ...parkingArea,
        centerPoint: JSON.parse(parkingArea.centerPoint),
        locationPolygon: JSON.parse(parkingArea.locationPolygon),
      })
      continue;
    }
    else if(alreadyComparedAreasIds.indexOf(parkingArea.parkingId) > -1){
      continue;
    }


    for(let j=i+1; j<parkingAreas.length; j++){
      let parkingAreaToCompare = parkingAreas[j];
      let parkingAreaToComparePolygon = JSON.parse(parkingAreaToCompare.locationPolygon);
      
      /** If polygon is actualy a point skip it */
      if(
        parkingAreaToComparePolygon.type === 'Point' || 
        alreadyComparedAreasIds.indexOf(parkingAreaToCompare.parkingId) > -1
      ){
        continue;
      }

      /** Fin overlaping on two polygons, and what is percentage of overlap */
      let intersect = turf.intersect(parkingAreaPolygon, parkingAreaToComparePolygon);
      if(intersect){

        const intersectArea = turf.area(intersect);
        const parkingAreaPolygonArea = turf.area(parkingAreaPolygon);
        const parkingAreaToComparePolygonArea = turf.area(parkingAreaToComparePolygon);
                
        const intersectPercentage = (intersectArea / (parkingAreaPolygonArea + parkingAreaToComparePolygonArea - intersectArea)) * 100;
       
        if(intersectPercentage >= 70){
          parkingAreasThatOverlaps.push(parkingAreaToCompare);
          alreadyComparedAreasIds.push(parkingAreaToCompare.parkingId);
        }

      }
    }

    if(parkingAreasThatOverlaps.length > 0){
      finalAreas.push({
        isGroup: true,
        nameCode: parkingArea.nameCode,
        logo: parkingArea.logo,
        color: parkingArea.color,
        parkingId: `group${parkingArea.parkingId}-${parkingAreasThatOverlaps.map(parkingArea => parkingArea.parkingId).join('-')}`,
        parkingsInGroup: [parkingArea, ...parkingAreasThatOverlaps],
        centerPoint: JSON.parse(parkingArea.centerPoint),
        locationPolygon: JSON.parse(parkingArea.locationPolygon)
      });
    }
    else {
      finalAreas.push({
        ...parkingArea,
        centerPoint: JSON.parse(parkingArea.centerPoint),
        locationPolygon: JSON.parse(parkingArea.locationPolygon)
      });
    }

  }

  return finalAreas;
};

export default groupAndFormatAreaData;