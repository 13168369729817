import moment from "moment";

const getPaymentSummary = (charges) => {

  const paymentProviders = [];
  charges.forEach(({ paymentProvider }) => {
    if (!paymentProviders.includes(paymentProvider)) {
      paymentProviders.push(paymentProvider);
    }
  });
  const vatPercentage = charges[0].vatPercentage;
  const paymentCurrency = charges[0].currency;

  const totalPriceNet = charges.reduce((total, charge) => {
    return total + charge.priceNet;
  }, 0);
  const totalPriceGross = charges.reduce((total, charge) => {
    return total + charge.priceGross;
  }, 0);
  const totalVat = totalPriceGross - totalPriceNet;

  return {
    paymentProviders,
    paymentCurrency,
    vatPercentage,
    totalPriceNet,
    totalPriceGross,
    totalVat,
  };
};

const genDurationString = (durationInMinutes, t) => {
  let delta = Math.abs(durationInMinutes) * 60;
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;
  const minutes = Math.ceil(delta / 60) % 60;
  delta -= minutes * 60;

  const result =
    (days ? days + (days === 1 ? +` ${t("day")} ` : ` ${t("days")}`) : "") +
    (hours ? hours + (hours === 1 ? ` ${t("hour")} ` : ` ${t("hours")}`) : "") +
    (minutes
      ? minutes + (minutes === 1 ? ` ${t("minute")} ` : ` ${t("minutes")}`)
      : "");
  return result;
};

const genPayedAtValue = (isCharged, startDatetime) => {
  return isCharged
    ? moment(startDatetime).local().format("HH:mm | DD MMM YYYY")
    : "/";
};

const genPayedWithValue = (isCharged, paymentProviders, t) => {
  return isCharged
    ? paymentProviders.join(", ").replace("Stripe", t("Debit or credit card"))
    : "/";
};

export {
  genPayedAtValue,
  genPayedWithValue,
  getPaymentSummary,
  genDurationString,
};
