import sprite from '@assets/sprite.svg';

/**
  * @desc Component responsible for rendering out SVG icons in our app.
  */
function IconSVG({
  name,
  fill,
  style,
  width,
  height,
  stroke,
  onClick,
  className
}){

  return (
    <svg
      fill={fill}
      style={style}
      stroke={stroke}
      height={height}
      onClick={onClick}
      className={className}
      width={width || height}
      >
      <use xlinkHref={ sprite + `#${name}` }/>
    </svg>
  );
}

export default IconSVG;
