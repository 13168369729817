import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { minutesIn } from "@utils/constants";

// Actions and async actions
import { getPrice, setParkingData } from "../../slice";

/**
 * @function
 * @name CalculatePrice
 * @desc Component is only reposnsible to get parkingTime values each time those values change.
 *       After that to call enpoint to get price for parking with selected duration.
 */
const CalculatePrice = () => {
  let dispatch = useDispatch();

  const { pricing, parkingTime } = useSelector((state) => state.parkingInput);
  // We must extract these two values like this due to async request behind them
  const pricingTypeId = pricing?.pricingTypeId;
  const pricingId = pricing?.pricingId;

  useEffect(() => {
    const {
      hours = 0,
      minutes = 0,
      days = 0,
      weeks = 0,
      months = 0,
    } = parkingTime;
    let duration =
      months * minutesIn.months +
      weeks * minutesIn.weeks +
      days * minutesIn.days +
      hours * minutesIn.hours +
      minutes;

    if (duration <= 0) {
      dispatch(setParkingData({ priceNet: 0, duration: 0 }));
    } else {
      dispatch(getPrice({ duration, pricingTypeId, pricingId }));
    }
  }, [pricingId, pricingTypeId, parkingTime]);

  return null;
};

export default CalculatePrice;
