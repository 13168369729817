import { useTranslation } from 'react-i18next';
import Button from '@components/Button';
import IconSVG from '@components/IconSVG';
import useReceipt from '../useReceipt';

const DownloadPdfButton = ({ parkingTicket }) => {
  const { downloadAsPdf } = useReceipt();
  const { t } = useTranslation();

  const handleDownloadAsPdf = () => downloadAsPdf(parkingTicket);

  return (
    <Button variant='primaryTransparent' onClick={handleDownloadAsPdf} title={t('Download')}>
      <IconSVG name='download' fill='var(--primary-color)' height='1.45rem' />
    </Button>
  );
}

export default DownloadPdfButton;
