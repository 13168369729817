import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';

const ModalStyled = styled(Modal)`
  background: rgba(0, 0, 0, 0.25);
  & .modal-body{
    box-shadow: var(--common-box-shadow);
    padding:0;
  }  
`;

const HederStyled = styled(Modal.Header)`
  color: white;
  background-color: ${({ $backgroundColor }) => $backgroundColor || 'orange'};
`;

const BackBtn = styled.div`
  padding-right: var(--content-padding-vertical);
`;

export {
  BackBtn,
  HederStyled,
  ModalStyled
};
