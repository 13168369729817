import { useSearchParams } from "react-router-dom";

/** Custom components */
import Button from '@components/Button'
import AnimatedLogo from '@components/AnimatedLogo';

/** Styles & iamges */
import images from '@images';
import styles from './styles.module.css';

/** Custom hooks */
import useVerifyEmail from './hooks/useVerifyEmail';

/** Utils */
import { getMobileOperatingSystem } from '@utils/common';

const EmailVerification = () => {
  
  /** Init */
  const [searchParams] = useSearchParams();
  const operatingSystem = getMobileOperatingSystem();

  /** Navigation data */
  const token = decodeURIComponent(searchParams.get("token"));
  const email = decodeURIComponent(searchParams.get("email"));

  /** Hook to verify email */
  const { isLoading, result } = useVerifyEmail(email, token);

  /**
   * @method
   */
  const SuccessMessage = () => {
    
    if(operatingSystem === "iOS" || operatingSystem === "Android"){
      return (
        <div className={styles.msgContainer}> 
          <img src={images.logo} alt="Logo" className={styles.image}/>
          Your email has been verified! You're all set to use our app. Click below to open the app.
          <br/>
          <br/>
          <Button text="Open app" variant="secondary" onClick={() => window.location.href = `${process.env.REACT_APP_APP_SCHEME}://`}/>
        </div>
      );
    }
    
    return (
      <div className={styles.msgContainer}>
        <img src={images.logo} alt="Logo" className={styles.image}/>
        Your email has been verified! Please use our mobile app to explore and enjoy our services.
      </div>
    );
  }

  return(
    <div className={styles.container}>
      {
        (isLoading) ?
        <AnimatedLogo/> :
        <SuccessMessage/>
      }
    </div>
  );
}

export default EmailVerification;
