import classes from './styles.module.css';

const Loader = () => {
  return <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.337402 50C0.337402 22.3858 22.7232 0 50.3374 0V0C77.9516 0 100.337 22.3858 100.337 50V50C100.337 77.6142 77.9516 100 50.3374 100V100C22.7232 100 0.337402 77.6142 0.337402 50V50Z" fill="#EF827F"/>
      <path className={classes.animatedPath}
        stroke="#fff" strokeLinejoin="round" strokeWidth="6.1"
        d="M33.4734 38.5429L35.5835 36.4008L40.9702 30.9825C42.843 29.0289 46.6604 26.495 51.8036 27.0877C53.6186 27.2444 57.8739 28.2825 60.9037 32.4436C61.7339 33.5837 63.9237 37.006 63.1802 42.2139C62.6228 44.8732 61.804 47.0051 59.3619 49.4882L41.4025 67.5231C38.3688 70.7485 32.8113 73.1806 26.5218 70.451C23.9939 69.3538 19.4168 65.8725 18.9608 59.4942C18.7508 56.558 19.5516 50.6669 25.5257 47.1791C28.1129 45.5708 35.2546 43.6193 41.1006 49.2769L59.7479 68.016C61.4943 69.8014 66.3929 73.005 72.7786 70.9943C74.966 70.3055 79.4461 68.0074 81.1999 62.3013C81.4314 61.6071 81.7433 59.9827 81.7433 58.7389V54.0309" />
  </svg>
}

export default Loader;
