/* Configuration object for stripe credit card elements   */
export default {
  style: {
    base: {
      color: "#212529",
      fontFamily: 'system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji"',
      fontWeight: 400,
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#CECECE",
      },
      border: '1px solid #cecece'
    },
    invalid: {
      color: "#BB4A48",      
      iconColor: "#BB4A48",
    },    
  }
}