import { useTranslation } from 'react-i18next';
import IconSVG from '@components/IconSVG';

// Import Styles
import styles from './styles.module.css';

function RoundButton({ onClick, disabled, parkingIsOngoing }){

  const { t } = useTranslation();

  /**
    * @method
    * @desc Method will render out label of button based on parking session.
    */
  function renderLabel(){

    /** Start */
    if(!parkingIsOngoing){
      return (
        <div>
          <IconSVG name="play" className={styles.icon} fill={'white'}/>
          <div className={styles.label}>Start</div>
        </div>
      );
    }

    /** Stop */
    return (
      <div>
        <IconSVG name="stop" className={styles.icon} fill={'white'}/>
        <div className={styles.label}>{t('Stop')}</div>
      </div>
    );
  }

  let className = `
    ${styles.container}
    ${styles.startBtn}
    ${(parkingIsOngoing) ? styles.stopBtn : styles.startBtn}
    ${(disabled) ? styles.disabledBtn : null}
  `;

  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={className}>

      {renderLabel()}

    </button>
  );
}

RoundButton.defaultProps = {
  disabled: false,
  onClick: () => console.log("MapToolbar middle button on click not set!!!")
};

export default RoundButton;
