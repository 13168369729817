import moment from "moment";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { sendReceiptToEmail as sendReceiptToEmailAction } from "../slice";
import { formatAmount } from "@utils/common/formatAmount";
import { formatPhoneNumber } from "@utils/phone";
import {
  genPayedAtValue,
  genPayedWithValue,
  getPaymentSummary,
  genDurationString,
} from "./helpers";
import generatePdf from "./generatePdf";

const useReceipt = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const downloadAsPdf = (parkingTicket) => {
    const {
      sessionTag,
      licensePlate,
      userPhoneNumber,
      userEmail,
      endDatetime,
      startDatetime,
      nameCode,
      parkingArea,
      providerWebsite,
      providerMobile,
      providerEmail,
      isCharged,
      charges,
    } = parkingTicket;

    const paymentSummary = getPaymentSummary(charges);
    const durationString = genDurationString(
      moment(endDatetime).diff(moment(startDatetime), "minutes", true),
      t
    );
    const receiptNumber = sessionTag.slice(-6);

    // TODO - collect this info from BE once available (country where parking is located)
    const countryCode = "SV";

    const pdfData = {
      receipt: ` ${t("Receipt")} #`,
      receiptNumber,
      nameCode,
      info: [
        {
          section: t("Client"),
          data: [
            [t("License plate"), licensePlate],
            [t("Phone"), formatPhoneNumber(userPhoneNumber)],
            [t("Email"), userEmail],
            [t("Paid at"), genPayedAtValue(isCharged, startDatetime)],
            [
              t("Paid with"),
              genPayedWithValue(isCharged, paymentSummary.paymentProviders, t),
            ],
          ],
        },
        {
          section: t("Parking"),
          data: [
            [t("Provider"), `${parkingArea.providerName} | ${nameCode}`],
            [t("Address"), parkingArea.name],
            [t("Phone"), providerMobile],
            [t("Email"), providerEmail],
            [t("Website"), providerWebsite],
            [
              t("Start"),
              moment(startDatetime).local().format("HH:mm | DD MMM YYYY"),
            ],
            [
              t("End"),
              moment(endDatetime).local().format("HH:mm | DD MMM YYYY"),
            ],
            [t("Duration"), durationString],
          ],
        },
        {
          section: t("Payment summary"),
          data: [
            [
              t("Price"),
              formatAmount(
                paymentSummary.totalPriceGross,
                paymentSummary.paymentCurrency,
                countryCode
              ),
            ],
            [
              `${t("VAT")} (${paymentSummary.vatPercentage}%)`,
              formatAmount(
                paymentSummary.totalVat,
                paymentSummary.paymentCurrency,
                countryCode
              ),
            ],
            [
              t("Total"),
              formatAmount(
                paymentSummary.totalPriceNet,
                paymentSummary.paymentCurrency,
                countryCode
              ),
              "bold",
            ],
          ],
        },
      ],
      footer:
        "Linky Tech AB  |  LINNÉGATAN 87 A  |  11523 Stockholm  |  Org.nr: 559203-1636",
    };

    generatePdf(pdfData, t);
  };

  const sendToEmail = (to, sessionTag) => {
    dispatch(sendReceiptToEmailAction({ to, sessionTag }));
  };

  return {
    downloadAsPdf,
    sendToEmail,
  };
};

export default useReceipt;
