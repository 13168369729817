/**
  * @function
  * @desc Function will extract coordinates for GeoJSON poylgon or point.
  * @param {Object} geoJSON - geoJSON object
  */
const extractCoordinates = (geoJson) => {
  let { type } = geoJson

  if(type === 'Polygon'){
    return geoJson.coordinates[0].map((coordinate) => {
      return {
        lat: coordinate[1],
        lng: coordinate[0]
      };
    });
  }

  return {
    lat: geoJson.coordinates[1],
    lng: geoJson.coordinates[0]
  }
};

export default extractCoordinates;
