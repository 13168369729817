import {combineReducers} from 'redux'; 
import * as Sentry from '@sentry/react';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { configureStore } from '@reduxjs/toolkit';

/** Reducers */
import menuReducer from '@containers/Menu/slice';
import mainReducer from '@containers/Main/slice.js';
import languageInputReducer from '@containers/LanguageInput/slice';
import keywordSearchReducer from '@containers/KeywordSearch/slice';
import parkingInputReducer from '@containers/ParkingInput/slice.js';
import parkingTicketReducer from '@containers/ParkingTicket/slice.js';

const reducers = combineReducers({
  main: mainReducer,
  menu: menuReducer,  
  parkingInput: parkingInputReducer,
  keywordSearch: keywordSearchReducer,  
  parkingTicket: parkingTicketReducer,
  languageInput: languageInputReducer    
});

const persistConfig = {
  storage,
  key: 'root',
  whitelist: ['main'] // only navigation will be persisted
};

const persistedReducer = persistReducer(persistConfig, reducers);

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export const store = configureStore({
  reducer: persistedReducer,
  enhancers: [sentryReduxEnhancer],
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  })
})
