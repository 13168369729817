import React from 'react';
import PropTypes from 'prop-types';

import images from '@images';
import Wrapper from './Wrapper';
import styles from './styles.module.css';

const Marker = ({ mapZoom, onClick }) => (
  <Wrapper
    mapZoom={mapZoom}
    onClick={onClick}>
    <img
      alt="Map Pin"
      className={styles.image}
      src={images.mapMarkerParking}/>
  </Wrapper>
);

Marker.defaultProps = {
  onClick: null,
};

Marker.propTypes = {
  onClick: PropTypes.func
};

export default Marker;
