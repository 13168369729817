import Spinner from 'react-bootstrap/Spinner';
import styles from './styles.module.css';

function Loading(){
  return (
    <div className={styles.listContainer}>
      <div className={styles.loadingContainer}>
        <Spinner animation="border" />
      </div>
    </div>
  );
}

export default Loading;
