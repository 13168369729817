import { useState } from "react";
import { Field, Formik, Form } from 'formik';
import { useSearchParams } from "react-router-dom";

/** Custom components */
import ValidationProgress from "./ValidationProgress";
import TextInput from '@components/TextInput';
import Button    from '@components/Button';

/** Styles & iamges */
import styles from './styles.module.css';

/** Custom hooks */
import useResetPassword from "./hooks/useResetPassword";

/** Utils */
import formValidation from "./formValidation";

const ResetPassword = () => {
  
  /** Init */
  const [searchParams] = useSearchParams();

  /** Form initial values */
  let initialValues = {
    password: '',
    confirmPassword: ''
  };

  /** State */
  const [ inputType, setInputType ] = useState("password");

  /** Navigation data */
  const token = decodeURIComponent(searchParams.get("token"));
  const email = decodeURIComponent(searchParams.get("email"));

  /** Hook to reset password email */
  const { isLoading, resetPassword, resetPasswordResult } = useResetPassword();

  /** 
    * @method
    */
  function onSubmit(values){
    const password = values.password;
    resetPassword(email, token, password);
  }

  /** 
    * @method
    */
  const toggleInputType = () => {
    setInputType(inputType === "password" ? "text" : "password");
  }

  if(resetPasswordResult?.status === "success"){
    return (
      <div className={styles.container}>
        <div className={styles.formCard}>
          <p className={styles.validationTitle}>Your password has been successfully changed. You can now log in using your updated credentials</p>
        </div>
      </div>
    );
  }

  return(
    <div className={styles.container}>
      <div className={styles.formCard}>
        <p className={styles.title}>Reset password</p>

        <div>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={formValidation}
          >
            {({ values, isValid }) => (
              <>
                <Form className={styles.formContainer}>
                  <Field
                    type={inputType}
                    name="password"
                    icon={inputType === "password" ? "eye" : "eyeClosed"}
                    onIconClick={toggleInputType}
                    component={TextInput}
                    className={styles.uppercase}
                    placeholder={'New Password'}/>

                  <Field
                    type={inputType}
                    name="confirmPassword"
                    component={TextInput}
                    placeholder={'Confirm password'}/>

                  <ValidationProgress values={values}/>  

                  <div className="d-grid gap-2">
                    <Button
                      loading={isLoading}
                      disabled={!isValid}
                      text={'Next'}
                      type='submit'/>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>    
      </div>
    </div>
  );
}

export default ResetPassword;
