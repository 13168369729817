/**
  * @function
  * @desc Function will take keyword and do a query to get predictions from google api for places.
  *
  * @return {Promise} - promise is returned, success wil yield places
  */
export const googleAutocomplete = async (keyword) => {

  return new Promise((resolve, reject) => {
    if (!keyword) {
      return reject("Need valid text input for google autocomplete!")
    }

    try {
      new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { input: keyword },
        resolve
      );
    } catch (error) {
      reject(error)
    }
  })
}
