import { useTranslation } from 'react-i18next';
import IconSVG from '@components/IconSVG';
import styles from './styles.module.css';

function Intro(){
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        {t('Loading')}
        <div className={styles.dotsWrapper}>
          <div className={styles.dots}>...</div>
        </div>
      </div>
      
      <IconSVG name='logoLinkyVertical' fill="black" className={styles.logo}/>
      
    </div>
  );
}

export default Intro;
