import Api from '../../Api';

class GetParkingsBySearchQuery extends Api {

  constructor(){
    super('/Parking/GetParkingsBySearchQuery');
  }

}

export default new GetParkingsBySearchQuery();
