import { useParams, useSearchParams } from "react-router-dom";

import Intro from "@components/Intro";

/** Screens */
import TokenLogin from "@containers/TokenLogin";
import ResetPassword from "@containers/Auth/ResetPassword";
import EmailVerification from "@containers/Auth/EmailVerification";

const OpenMobileApp = () => {
  const { action } = useParams();
  const [searchParams] = useSearchParams();
  switch (action) {
    case "emailVerification":
      return (<EmailVerification/>);

    case "resetPassword":
      return (<ResetPassword/>);

    case "tokenLogin":
      return (<TokenLogin/>);

    // case "resetPassword":
    //   const token = encodeURIComponent(searchParams.get("token"));
    //   const email = encodeURIComponent(searchParams.get("email"));
    //   const redirectUrl =
    //     process.env.REACT_APP_MOBILE_APP_SCHEME +
    //     `://auth/${action}?token=${token}&email=${email}`;
    //   window.location.replace(redirectUrl);
    //   window.close();
    //   break;
  }

  return <Intro />;
};

export default OpenMobileApp;
