import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from "react-router-dom";

/** Custom components */
import Button from '@components/Button';

// Import Styles & Images
import styles from './styles.module.css';

function TokenLogin() {

  /** Init */
  const { t, i18n } = useTranslation();
  const [ searchParams ] = useSearchParams();

  /** State */
  const [ error, setError ] = useState(null);

  /** Search params from URL and config */
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const language = searchParams.get("language") || "en";
  const appScheme = process.env.REACT_APP_APP_SCHEME;
  const appStoreUrl = process.env.REACT_APP_APP_STORE_URL;
  const playStoreUrl = process.env.REACT_APP_PLAY_STORE_URL;
  
  /** Change language if provided in link */
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);  

  /**
    * @method
    * @description Method will take url params token and email and open app using app scheme.
    * Url params will be ENCODED when making deep link because app will decode those.
    */
  const openAppAndAuthorize = () => {
    
    console.log("Ovo su search params");
    console.log(searchParams);
    console.log("Email");
    console.log(email);
    console.log("token");
    console.log(token);

    const emailEcoded = encodeURIComponent(email);
    const tokenEncoded = encodeURIComponent(token);      
    const openAppUri = `${appScheme}://auth/tokenLogin?token=${tokenEncoded}&email=${emailEcoded}`;
    console.log("Open app uri:");
    console.log(openAppUri);  
    
    try {
      window.location.replace(openAppUri);
      window.close();
    } catch (error) {
      setError(error);
    }
  }

  if(error){
    return (
      <div>App couldn't be opened!</div>
    );
  }

  return (
    <div className={styles.container}>

      <div className={styles.content}>
        {/* <div>
          {t('new_app_rollout_msg')}
        </div>
        <br/> */}

        <p> 
          <strong> {t('upgrade_or_download')}</strong>
          <br/><br/>
            1. <strong>iOS</strong> {t('users')}, <a href={appStoreUrl} target="_blank">{t('click_here')}</a> {t('upgrade_ios')}
            <br/>
            2. <strong>Android</strong> {t('users')}, <a href={playStoreUrl} target="_blank">{t('click_here')}</a> {t('upgrade_android')}
        </p>

        <p>
            {t('after_upgrade_msg')}
        </p>


        <div className={styles.btnContainer}>
          <Button text={t('open_app')} variant="secondary" onClick={() => openAppAndAuthorize()} className={styles.btn}/>
        </div>
        <br/>

        <div>
          {t('need_support')}
          <br/>
          <a href="mailto:support@linky.se?subject=Customer Login Issue">support@linky.se</a>      
        </div>  

      </div>

    </div>
  );
}

export default TokenLogin;
