import { createAsyncThunk } from '@reduxjs/toolkit';

/** Endpoints */
import GetPricingDetails from '@endpoints/Parking/GetPricingDetails';

/**
  * @function
  * @async
  * @desc Function will receive parkingId and fetch details about that parking area from backend.
  * For now only pricing data is fetched, after that data is added to selectedArea as it belongs to it if parkingId is sent.
  * NOTE: If parking id is groupId, dont fetch pricing for it.
  */
const getAreaDetails = createAsyncThunk(
  'main/getAreaDetails',
  async ({ parkingId }, thunkAPI) => {

    const queryFilter = { parkingId };

    if(parkingId.toString().includes("group")){
      return {};
    }

    try {

      let result   = await GetPricingDetails.get(queryFilter);
      let pricings = result.data;

      return { pricings };

    } catch (error) {
      console.log("Error main/getAreaDetails");
      console.log(error);
    }
  }
);

export default getAreaDetails;
