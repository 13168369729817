import images from '@assets/images';
import styles from './styles.module.css';

const ValidationProgress = ({ values }) => {
  
  let { password, confirmPassword } = values;

  let oneNumberRegex = /(?=.*?\d)/;
  let eightCharsLongRegex = /^.{8,}$/;
  let oneUppercaseLetterRegex = /(?=.*?[A-Z])/;

  const hasOneNumber = oneNumberRegex.test(password);
  const isEightCharsLong  = eightCharsLongRegex.test(password);
  const hasOneUppercaseLetter = oneUppercaseLetterRegex.test(password);
  const arePasswordSame = (password && confirmPassword) && (password === confirmPassword);

  return(
    <div>
      <p className={styles.validationTitle}>Your password must be:</p>
      <div className={styles.row}>
        <img src={isEightCharsLong ? images.trueIcon : images.falseIcon} alt="True or False" className={styles.icon}/>
        <p className={styles.validationItem}>at least 8 characters long</p>
      </div>

      <div className={styles.row}>
        <img src={hasOneUppercaseLetter ? images.trueIcon : images.falseIcon} alt="True or False" className={styles.icon}/>
        <p className={styles.validationItem}>one uppercase letter</p>
      </div>  

      <div className={styles.row}>
        <img src={hasOneNumber ? images.trueIcon : images.falseIcon} alt="True or False" className={styles.icon}/>
        <p className={styles.validationItem}>at least one number</p>
      </div>

      {/* <p className={styles.validationTitle}>Your password must have:</p> */}
      <div className={styles.row}>
        <img src={arePasswordSame ? images.trueIcon : images.falseIcon} alt="True or False" className={styles.icon}/>
        <p className={styles.validationItem}>matching with confirm password</p>
      </div>
      
    </div>  
  );
}

export default ValidationProgress;
