import { useTranslation, Trans } from 'react-i18next';
import { Field, Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

// Custom components
import TextInput from '@components/TextInput';
import Button    from '@components/Button';
import Link      from '@components/Link';

// Utils
import formValidation from './formValidation';
import { storage } from '@utils/common';
import { getWPAlias } from '../../../../utils/common';
import Logger from '@app/Logger';

// Actions
import {setParkingData as setParkingDataAction} from '../../slice';

import classes from './styles.module.css';

function UserInfo({ slideIndex, setSlideIndex }){

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // We need pricings in place in order to proceed to next step (pricing/duration)
  const pricings = useSelector(state => state.main.selectedArea.pricings);

  // TODO - replace with redux persist
  const lastKnowInfo = JSON.parse(storage.get('lastKnownInfo'));
  let initialValues = {
    email: lastKnowInfo?.email || '',
    phoneNumber: lastKnowInfo?.phoneNumber || '',
    licensePlate: lastKnowInfo?.licensePlate || ''
  };

  function onSubmit(values){
    // TODO - replace with redux persist
    storage.update('lastKnownInfo', values);

    Logger.setUser(values);
    setSlideIndex(slideIndex + 1);
    dispatch(setParkingDataAction(values));
  }

  // Generate link to Wordpress site page
  const genWpUrl = page => process.env.REACT_APP_LINKY_WP_URL + '/' + getWPAlias(i18n.language, page);
  
  return (
    <Formik
      onSubmit={onSubmit}
      validate={formValidation}
      initialValues={initialValues}>
      {({ isValid }) => (
        <Form className={classes.formContainer}>
          <Field
            name="licensePlate"
            component={TextInput}
            className={classes.uppercase}
            placeholder={t('License plate')}/>

          <Field
            name="phoneNumber"
            component={TextInput}
            placeholder={t('Phone number')}/>

          <Field
            name="email"
            component={TextInput}
            placeholder={t('Email address')}/>
          
          <div className={classes.infoText}>
            <Trans i18nKey={'accept_pp_and_tac'}
              components={{
                pp: <Link to={genWpUrl("pp")} />,
                tac: <Link to={genWpUrl("tac")} />
              }} />
           
          </div>

          <div className="d-grid gap-2">
            <Button
              disabled={!isValid || !pricings}
              loading={!pricings}
              text={t('Next')}
              type='submit'/>
          </div>
        </Form>
      )}

    </Formik>
  );
}

export default UserInfo;
