import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'

import Label from '../Label';
import Button from '@components/Button';

import styles from './styles.module.css';

// Utils
import { extractCoordinates, getMobileOperatingSystem } from '@utils/common';

function Header(){

  /** Redux data */
  const { code, name, licensePlate, parkingArea } = useSelector(state => state.parkingTicket);

  const { t } = useTranslation();

  /**
   * @desc Method will try to open google maps on smart phones with route from user to parking area.
   * TODO: User location is hardcoded, fix it after GPS module is done.
   */
  function openGMapsWithDirections(){

    let url = null;
    let operatingSystem = getMobileOperatingSystem();
    let parkingAreaCoordinates = extractCoordinates(parkingArea.centerPoint);

    let userLat = 59.318736328;
    let userLng = 18.045934438;
    let saddr   = `${userLat},${userLng}`;

    let destinationLat = parkingAreaCoordinates.lat;
    let destinationLng = parkingAreaCoordinates.lng;
    let daddr          = `${destinationLat},${destinationLng}`;

    if(operatingSystem === 'iOS'){
      url = `comgooglemaps://?saddr=${saddr}&daddr=${daddr}&directionsmode=driving`;
    }
    else if(operatingSystem === 'Android'){
      url = `google.navigation:q=${destinationLat},${destinationLng}`;
    }
    else {
      url = 'https://www.google.com/';
    }

    document.location.replace(url);
  }

  return (
    <Container>
      <Row className={styles.row}>
        <Col>
          <Label>{licensePlate}</Label>
          <div>
            <span className={styles.parkingName}>{name}</span>
          </div>
        </Col>
        <Col>
          <div className="d-grid gap-2">
            <Button
              text={t('Directions')}
              variant='primaryTransparent'
              onClick={openGMapsWithDirections}/>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Header;
