import { useSelector } from 'react-redux';

// Custom components
import ParkingAreaBarStyled from './ParkingAreaBar.styled';

function ParkingAreaBar(){

  let selectedArea = useSelector((state) => state.main.selectedArea);

  if(!selectedArea){
    return null;
  }

  const { logo, providerName, nameCode, color } = selectedArea;

  return(
    <ParkingAreaBarStyled
      logo={logo}
      code={nameCode}
      backgroundColor={color}
      providerName={providerName}
    />
  );
}

export default ParkingAreaBar;
