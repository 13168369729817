import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import i18Config, { supportedLanguages } from "./i18Config";
import { storage, updateMomentLocale, getBrowserLanguage } from '@utils/common';

// Get browser selected language
const browserLanguage = getBrowserLanguage();

// Get language selected through the app
const lastKnownInfo = storage.get('lastKnownInfo');
const { language: appLanguage } = lastKnownInfo ? JSON.parse(lastKnownInfo) : {};

// Determine language canidate - appLanguage has priority
const languageCandidate = appLanguage || browserLanguage;

// Check if candidate is on the list of supported languages 
// or fallback to default - first from the supported list
const selectedLanguage = supportedLanguages.includes(languageCandidate) 
  ? languageCandidate : supportedLanguages[0];

// Initialize i18 with chosen language
i18n.use(initReactI18next).init(i18Config(selectedLanguage));

// Set Moment local names for months
updateMomentLocale(i18n.language);