import Button from 'react-bootstrap/Button';
import IconSVG from '@components/IconSVG';
import useToastify from '@containers/ToastMessages/useToastify';
import { t } from 'i18next';

import styles from './styles.module.css';

import GPS from '@utils/GPS';

function LocationButton({ setMapCenter }){

  const errorToast = useToastify();

  const handleOnClick = async () => {  
   
    try{            
      // Get location data
      const position = await GPS.getCurrentLocation();                              
      // Center map to users location
      setMapCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude
      });  
    } catch(error){    
       // Show single error notification
       if(!errorToast.isVisible()){                      
        errorToast.notify({      
          title:(t('Location blocked')),
          message:t('To use GPS positioning on the map, you should allow it in browser’s setup'), 
          type: 'error' 
        });
      }
    }       
  }

  return (
    <div className={styles.container}>
      <Button
        bsPrefix={`btn btn-lg ${styles.btn}`}
        onClick={handleOnClick}>
          <IconSVG name="gps" fill="#CECECE" height="1.6rem"/>
      </Button>
    </div>
  );
}

export default LocationButton;
