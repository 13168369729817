import { useState } from 'react';

/** Endpoints */
import ResetPassword from '@endpoints/Authentication/ResetPassword';

function useResetPassword() {

  /** State */
  const [ resetPasswordResult, setResetPasswordResult ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);

  /**
   * @method
   * @description
   */
  const resetPassword = async (email, token, password) => {
    setIsLoading(true);

    try {
      const data = {
        email,
        token,
        password
      };

      const result = await ResetPassword.post(null, data);
      
      setResetPasswordResult(result.data);

    } catch (error) {
      console.log("Error on resetPassword:");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return { 
    isLoading,
    resetPassword,
    resetPasswordResult 
  };
}

export default useResetPassword;