import { Routes, Route } from "react-router-dom";
import * as Sentry from "@sentry/react";

// Screen Imports
import MainScreen from "./MainScreen";
import ConfirmPayment from "@containers/ConfirmPayment";
import ParkingAreaCodeQueryRoute from "./ParkingAreaCodeQueryRoute";
import OpenMobileApp from "./OpenMobileAppScreen";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function Navigation() {
  return (
    <SentryRoutes>
      <Route path="/" element={<MainScreen />} />
      <Route path="/:parkingAreaCode" element={<ParkingAreaCodeQueryRoute />} />
      <Route path="/auth/:action" element={<OpenMobileApp />} />
      <Route path="/confirmPayment" element={<ConfirmPayment />} />
    </SentryRoutes>
  );
}

export default Navigation;
