import { createSlice } from '@reduxjs/toolkit';

/**
  * @namespace
  * @property {Bool}  isVisible - show/hide menu  
  */

const initialState = {
 isVisible: false
};

/**
  * @name menuSlice
  * @desc This reducer is responsible for storing menu related props  
  */
export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    toggleMenu: state => {
      state.isVisible = !state.isVisible
    }
  }  
});

/** Actions */
export const { toggleMenu } = menuSlice.actions;
/** Reducer */
export default menuSlice.reducer;
