import { useTranslation } from 'react-i18next';

import Loading from './Loading';
import ListItem from './ListItem';
import styles from './styles.module.css';

function List({ isLoading, data, onItemClick, keyword }) {

  const { t } = useTranslation();

  if(isLoading){
    return <Loading/>;
  }
  
  if(keyword && data.length === 0){
    return (
      <div className={styles.listContainer}>
        <div className={styles.loadingContainer}>
          {t('No results found')}
        </div>
      </div>
    );
  }

  return (
    <div className={styles.listContainer}>
      {
        data.map((item, idx) => {
          return (
            <ListItem
              item={item}
              onClick={onItemClick}
              key={idx}
              keyword={keyword}
            />
          );
        })
      }
    </div>
  );
}

export default List;
