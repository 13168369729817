import { useState, useRef, useEffect } from 'react';
import IconSVG from '@components/IconSVG';
import classes from './styles.module.css';

/**
  * @component
  * @name WheelPicker
  * @desc input component with list of available options to select
  * @param {String} name - field name 
  * @param {String} label - field label 
  * @param {Array} options - array of possible options with key/value pairs [{key:'optionKey', value:'Some Value' },...]
  * @param {Number} visibleOptions - possible options visible at the same time (should be even number)
  * @param {Function} onChange - method to execute on selection change
  */
const WheelPicker = ({ name, label, options, value, visibleOptions = 5, onChange, disabled }) => {
  
  // Reference to the list container
  const ref = useRef(null);
  // Number of empty items around list
  const placeholderNum = Math.floor(visibleOptions / 2);
  // Initially selected option 
  const selectedOptionIndex = options.findIndex(opt => opt.value === value);
  // Calculated width for each option item
  const [itemWidth, setItemWidth] = useState(0);
  // Selected option index 
  const [index, setIndex] = useState(selectedOptionIndex > -1 ? selectedOptionIndex : options.length - 1);
  // Available option in teh list
  const [items, setItems] = useState([]);

  // Prev/next buttons < >
  const ActionBtn = ({ icon, onClick, muted }) => {        
    const btnClass = `${classes.actionBtn} ${muted && classes.muted}`;
    const btnAction = !muted ? onClick : null;
    return (
      <div className={btnClass}
        onClick={btnAction}>
        <IconSVG name={icon} className={classes.actionBtnIcon} />
      </div>
    );
  }

  // generates option - list item
  const generateItem = (label, idx) => {
    let itemCls = null;
    if(idx === index + placeholderNum){
      itemCls = classes.selectedOption;
    } else if(idx === (index + placeholderNum - 1) || idx === (index + placeholderNum + 1)){
      itemCls = classes.nextOption
    }
    
    return (
      <div
        key={idx}
        style={{ width: itemWidth + 'px' }}
        className={`${classes.item} ${itemCls}`}>
        {label}
      </div>
    );
  }

  // Generates list of options
  const generateList = () => {
    return (
      <div className={classes.listWrapper} ref={ref}>
        <div style={{ left: calcOffset() + 'px' }} className={classes.list}>
          {items.map(({ label }, idx) => generateItem(label, idx))}
        </div>
      </div>
    );
  }

  // Option selection handlers
  const handlePrev = () => { 
    if(!disabled) {
      setIndex(index - 1);
    }
  }
  const handleNext = () => {
    if(!disabled){
      setIndex(index + 1);
    }
  }

  // Offset in px from the left according to selected option
  const calcOffset = () => index * itemWidth * -1;

  // Get container width and calc list item width
  useEffect(() => {
    if (!ref.current) return null;
    setItemWidth(ref.current.offsetWidth / visibleOptions);
  }, [ref.current]);

  // Add placeholders to the option list
  useEffect(() => {
    if (!options) return null;
    const placeholders = [];
    // generate placeholder items that will be placed around option list
    for (let i = 0; i < placeholderNum; i++) placeholders.push({ value: null, label: '' });
    const updatedItems = [...placeholders, ...options, ...placeholders];
    setItems(updatedItems);
  }, [ options ]);

  // Execute onChange every time option is selected
  useEffect(() => {
    if (items.length < index + placeholderNum) return;
    onChange(name, items[index + placeholderNum].value);
  }, [index])

  // Find option with preselected value and set it's index as active index
  useEffect(() => {
    if (!items.length) return;
    setIndex(items.findIndex(i => i.value === value) - placeholderNum);
  }, [value])

  return (
    <div className={classes.container}>
      <div className={classes.label}>{label}</div>
      <div className={classes.selectedOptionBackground} style={{ width: itemWidth }}></div>
      <ActionBtn onClick={handlePrev} icon={'caretLeft'} muted={index === 0} />
      {generateList()}
      <ActionBtn onClick={handleNext} icon={'caretRight'} muted={index + visibleOptions === items.length} />
    </div>
  );
}

export default WheelPicker;