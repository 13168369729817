import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Map from './Map';
import MapToolbar from '@containers/MapToolbar';
import ParkingInput from '@containers/ParkingInput';
import KeywordSearch from '@containers/KeywordSearch';
import ParkingTicket from '@containers/ParkingTicket';
import ParkingAreaBar from '@containers/ParkingAreaBar';
import LocationButton from '@containers/Location/LocationButton';
import Loader from '@containers/Loader';
import Menu from '@containers/Menu';
import LanguageInput from '../LanguageInput';

import { setParkingData } from '@containers/ParkingInput/slice';

/**
  * @desc Component will house map and all other components on main screen of our app.
  * Component will also keep track of google API map object as that is needed for both map and address search.
  * TODO: code for showing parking input could be optimized
  */
function Main(){

  /** State data */  
  let [ googleMapRef, setGoogleMapRef ] = useState(null);
  let [ showParkingInput, setShowParkingInput ] = useState(false);
  let [ mapCenter, setMapCenter ] = useState(null);
  const dispatch = useDispatch();

  /** Navigation data */
  let [ searchParams, setSearchParams ] = useSearchParams();
  let sessionTag = searchParams.get('sessionTag');
  let chargeId = searchParams.get('chargeId');

  /** Redux data */
  const paymentData = useSelector(state => state.parkingInput.paymentData);
  const { isEditing } = useSelector(state => state.parkingTicket);
  const { selectedArea } = useSelector(state => state.main);

  /**
    * @desc Once we have sessionTag in url and we want to edit our ticket it will show parking input todo so
    */
  useEffect(() =>{
    if(sessionTag && isEditing){
      setShowParkingInput(true)
    }
  }, [isEditing, sessionTag]);

  useEffect(() => {
    if(chargeId){
      dispatch(setParkingData({ isProcessing: true, paymentData: { chargeId }}));
    }
  }, [chargeId])


  return (
    <div>
      <div>
        
        <ParkingAreaBar/>

        { !selectedArea &&
        <KeywordSearch
          setMapCenter={setMapCenter}
          googleMapRef={googleMapRef}/>}

      </div>

      <Map
        setGoogleMapRef={setGoogleMapRef}        
        center={mapCenter}
        setCenter={setMapCenter}
      />

      <MapToolbar
        showParkingInput={showParkingInput}
        setShowParkingInput={setShowParkingInput}/>

      <LocationButton setMapCenter={setMapCenter}/>

      { // First we must be sure that paymentData in state.parkingInput is available,
        // since we use chargeId and clientSecret props for logic there.
        // We declare those vars with spread operator and on slower connections
        // if paymentData object is not available it will crash the app.
        // TODO: maybe to consider moving that logic to ParkingInput 
        paymentData &&
          <ParkingInput
            show={showParkingInput}
            setShowParkingInput={setShowParkingInput}/>
      }
      <ParkingTicket setMapCenter={setMapCenter}/>

      <Menu />

      <LanguageInput />

      <Loader />

    </div>
  );
}

export default Main;
