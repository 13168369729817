import Logger from "@app/Logger"

export const handleError = error => {
  // TODO - show error message through Toast or something else
  if(process.env.REACT_APP_ENV === 'dev') {
    console.error(error);
  }
  
  // Log error record
  Logger.log(error, 'error');
}