import moment from 'moment';

const updateMomentLocale = language => {
  
  const monthNamesMap = {
    en: {    
        monthsShort:
          ["Jan", "Feb", "Mar", "Apr", "May", "Jun", 
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]    
      },
    sv: {    
        monthsShort:
        ["jan.", "feb.", "mars", "apr.", "maj", "juni", 
        "juli", "aug.", "sep.", "okt.", "nov.", "dec."]
    
    }
  }

  moment.updateLocale(language, monthNamesMap[language]);
}

export default updateMomentLocale;