import { createAsyncThunk } from "@reduxjs/toolkit";

/** Endpoints */
import { default as swishExtendParking } from "@endpoints/Swish/ExtendParking";
import { default as stripeExtendParking } from "@endpoints/Stripe/ExtendParking";

/**
 * @function
 * @async
 * @desc action will extend parkign by some duration
 */
const extendParking = createAsyncThunk(
  "parkingInput/extendParking",
  async (
    { paymentProvider, paymentMethodId, sessionTag, duration },
    { rejectWithValue }
  ) => {
    const paymentProviderEndpoint = {
      swish: swishExtendParking,
      stripe: stripeExtendParking,
    }[paymentProvider];
    const body = { duration, sessionTag };

    try {
      let result = await paymentProviderEndpoint.post(null, body);
      return { ...result.data, paymentMethodId };
    } catch (error) {
      console.log("Error parkingInput/extendParking");
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export default extendParking;
