function getWPAlias(language, page){  
  const map = {
    en: {
      pp: 'en/privacy-policy',
      tac: 'en/terms-and-conditions',
      docs: 'en/docs'
    },
    sv: {
      pp: 'integritetspolicy',
      tac: 'allmanna-villkor',
      docs: 'docs'
    }
  }
  return map[language][page];
}

export default getWPAlias;