
import { useSelector, useDispatch } from 'react-redux';
import { Field, Formik, Form } from 'formik';
import { useTranslation } from 'react-i18next';

import Modal from '@components/Modal';
import Button from '@components/Button';
import Dropdown from '@components/Dropdown';

import { toggleLanguageModal } from '@containers/LanguageInput/slice';

import { storage } from '@utils/common';

import { supportedLanguages } from '../../i18/i18Config';
import images from '@images';

import classes from './styles.module.css';

const LanguageInput = () => {
  const { isVisible } = useSelector(state => state.languageInput);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const initialValues = { language: i18n.language };

  const handleHideModal = () => dispatch(toggleLanguageModal());

  const handleSubmit = ({ language }) => {
    i18n.changeLanguage(language);
    storage.update('lastKnownInfo', { language });
    dispatch(toggleLanguageModal());
  }

  const generateOptions = () => {
    const options = [];
    supportedLanguages.forEach(lan => {
      options.push({ 
        value: lan, 
        title: t(`language_${lan}`),
        icon: <img src={images[`lang_${lan}`]} />
      });
    })
    return options;
  }

  return (
    <Modal
      show={isVisible}      
      title={t('Language')}
      onHide={handleHideModal}>
      <div className={classes.contentContainer}>
        {/* Introduction text */}
        <div className={classes.text}>
          { t('Change the Linky default language.') }
        </div>
        {/* Form */}
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}>
          <Form>
            <Field name="language" component={Dropdown} options={generateOptions()} />          
            <Button
              disabled={false}
              text={t('Save changes')}
              type='submit'
              className={classes.button}
            />
          </Form>
        </Formik>
      </div>
    </Modal>
  )
}

export default LanguageInput;