import { useEffect } from "react";
import { Field } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { setParkingData as setParkingDataAction } from "../../../slice";
import { minutesIn } from "@utils/constants";

import DurationPicker from "./DurationPicker";

import classes from "../styles.module.css";

/**
 * @component
 * @name SelectDuration
 * @desc This component enables user to select parking duration
 */

const SelectDuration = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { values, setValues } = useFormikContext();

  const {
    pricing: selectedPricing,
    duration,
    parkingTime,
    priceIsLoading,
  } = useSelector((state) => state.parkingInput);

  const handleOnChange = (name, value) => {
    const formValues = { ...values, [name]: value };
    const maxDuration = selectedPricing.maxParkingTime;
    // Calc available minutes after changing hours
    if (name === "hours") {
      if (
        maxDuration &&
        formValues.hours * 60 + formValues.minutes > maxDuration
      ) {
        formValues.minutes = maxDuration - formValues.hours * 60;
      }
      // or reset if user reached 24 hours
      if (formValues.hours === 24) {
        formValues.minutes = 0;
      }
    }
    setValues(formValues);
  };

  // For pricings with 0 as avilable option (minutes/hours) we can use the calculated value
  // for others we shold use step as minimum value
  const calcMinValue = (min, step) => (min < 1 ? min : step);

  // Minimum step should be 1
  const calcStep = (step) => (step > 1 ? step : 1);

  // Propagate selected time to app state
  useEffect(() => {
    dispatch(setParkingDataAction({ parkingTime: values }));
  }, [values]);

  if (!selectedPricing) return null;

  return (
    <div className={classes.contentContainer}>
      {selectedPricing.units.map(({ name, min, max }) => {
        const stepInMinutes = selectedPricing.pricingStep / minutesIn[name];
        return (
          <Field
            key={name}
            component={DurationPicker}
            name={name}
            label={t(name)}
            min={calcMinValue(min, stepInMinutes)}
            max={max}
            step={calcStep(stepInMinutes)}
            onChange={handleOnChange}
            disabled={priceIsLoading}
            dependent={{
              parkingTime,
              duration,
              maxDuration: selectedPricing.maxParkingTime,
              maxPrice: selectedPricing.maxPrice,
            }}
          />
        );
      })}
    </div>
  );
};

export default SelectDuration;
