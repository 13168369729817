import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';

import IconSVG from '@components/IconSVG';
import { HederStyled, BackBtn, ModalStyled } from './Modal.styled';

/**
  * @desc This is modal component for app.
  * TODO: since modal is hooken on state to be able to see selectedArea, decide if Modal will become container.
  * TODO: Dummy loading is implemented, replace it once linky loading is made
  */
function CustomModal(props){

  let {
    show,
    title,
    onHide,
    onBack,
    children,
    className,    
    showCloseButton
  } = props;

  let selectedArea = useSelector((state) => state.main.selectedArea);
  let backgroundColor = (selectedArea) ? selectedArea.color : 'var(--brand-color)';

  /**
    * @method
    */
  function renderBackBtn(){
    if(!onBack){
      return null;
    }

    return (
      <BackBtn onClick={onBack}>
        <IconSVG name='chevron' fill='white' height='1.45rem'/>
      </BackBtn>
    );
  }

  return(
    <ModalStyled
      centered
      show={show}
      onHide={onHide}
      backdrop={false}>

      <HederStyled
        closeVariant='white'
        closeButton={showCloseButton}
        $backgroundColor={backgroundColor}>
        {renderBackBtn()}
        <Modal.Title>{title}</Modal.Title>
      </HederStyled>

      <Modal.Body>
        {children}
      </Modal.Body>

    </ModalStyled>
  );
}

CustomModal.defaultProps = {
  show: false,
  onBack: null,
  isLoading: false,
  title: 'Modal Title',
  showCloseButton: true,
  onHide: () => console.log("On hide not set")
};

export default CustomModal;
