import { createAsyncThunk } from '@reduxjs/toolkit';

/** Endpoints */
import GetPrice from '@endpoints/Pricing/GetPrice';

/**
  * @function
  * @async
  * @desc This async action will fetch price for parking from backend based on time(parking duration).
  *
  * @param {number} duration - duration in minutes
  * @param {number} pricingId - id of pricing from selected area
  */
const getPrice = createAsyncThunk(
  'parkingInput/getPrice',
  async ({ duration, pricingId, pricingTypeId }, thunkAPI) => {

    const queryFilter = {
      duration,
      pricingTypeId,
      pricingId
    };

    try {

      let result = await GetPrice.get(queryFilter);
      let { currency, priceNet, priceGross } = result.data;

      return {
        duration,
        currency,
        priceNet,
        priceGross
      };
    } catch (error) {
      console.log("Error parkingInput/getPrice");
      console.log(error);
    }
  }
);

export default getPrice;
