import libphonenumber from "google-libphonenumber";
const phoneUtils = libphonenumber.PhoneNumberUtil.getInstance();
const PNF = libphonenumber.PhoneNumberFormat;

const parsePhoneNumber = (data) => {
  try {
    const phoneNumber = phoneUtils.parseAndKeepRawInput("+" + parseInt(data));
    const code = phoneNumber.getCountryCode();
    const number = String(phoneNumber.getNationalNumber());
    const region = phoneUtils.getRegionCodeForNumber(phoneNumber);
    return { code, number, region };
  } catch (error) {
    //console.log("Error parsing number", error);
    return {
      code: "",
      number: "",
      region: "",
    };
  }
};

const formatPhoneNumber = (data) => {
  try {
    const phoneNumber = phoneUtils.parse(data);
    if (phoneNumber.getCountryCode() === 1) {
      return "+1 " + phoneUtils.format(phoneNumber, PNF.NATIONAL);
    }
    return phoneUtils.format(phoneNumber, PNF.INTERNATIONAL);
  } catch (e) {
    return data;
  }
};

export { parsePhoneNumber, formatPhoneNumber };
