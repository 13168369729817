// Import Styles
import styles from './styles.module.css';

function Button({ children, onClick }){
  return (
    <button
      onClick={onClick}
      className={styles.container}>
      { children }
    </button>
  );
}

export default Button;
