/** Styles */
import styles from './styles.module.css';

/** Images */
import images from '@assets/images';

/** Utils */
import { getMobileOperatingSystem } from '@utils/common';

/**
 * @function
 * @desc 
 */
function UseAppMsg() {

  const platform = getMobileOperatingSystem();
  
  const imgSrc = (platform === 'iOS') ? images.appStore : images.playStore;
  const alt = (platform === 'iOS') ? 'App Store' : 'Play Store';
  const storeUrl = (platform === 'iOS') ? process.env.REACT_APP_APP_STORE_URL : process.env.REACT_APP_PLAY_STORE_URL;

  const renderStoreButton = () => {
    return (
      <a href={storeUrl}>
        <img src={imgSrc} alt={alt} className={styles.storeButton}/>
      </a>
    );
  }

  return (
    <div className={styles.container}>
      <p className={styles.text}>Unlock smart parking with camera automation!</p>
      <p className={styles.text}>Get started by downloading our app.</p>
      <div className={styles.buttonContainer}>
        {renderStoreButton()}
      </div>
    </div>
  );
}

export default UseAppMsg;
