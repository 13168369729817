const images = {
  mapMarkerParking: require('./mapMarkerParking.png'),
  logoVertical: require('./logoVertical.png'),
  logoPayPal: require('./logoPayPal.png'),
  creditCard: require('./creditCard.png'),
  logoSwish: require('./logoSwish.png'),
  logoApple: require('./logoApple.png'),
  logoGoogle: require('./logoGoogle.png'),
  appPin: require('./appPin.png'),
  search: require('./search.png'),
  intro: require('./intro.jpg'),
  lang_sv: require('./langSv.png'),
  lang_en: require('./langEn.png'),
  playStore: require('./playStore.png'),
  appStore: require('./appStore.png'),
  logo: require('./logo.png'),
  falseIcon: require('./falseIcon.png'),
  trueIcon: require('./trueIcon.png')
};

export default images;
