function formValidation({ email, licensePlate, phoneNumber }) {
  let errors = {};
  const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  const regexPhone = /^(00|\+)[0-9\\s.\\/-]{6,20}$/;
  const regexLicensePlate = /^[a-zA-Z0-9]+$/;

   if (!regexPhone.test(phoneNumber)){
    errors.phoneNumber = "Invalid Phone number";
  }

  if (!regexLicensePlate.test(licensePlate)){
    errors.licensePlate = "Please use only letters and numbers";
  }

  if(email && !regexEmail.test(email)){
    errors.email = "Invalid Email";
  }

  if(!licensePlate){
    errors.licensePlate = "Required";
  }

  if(!phoneNumber){
    errors.phoneNumber = "Required";
  } 


  return errors;
}

export default formValidation;
