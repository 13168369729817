import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  useStripe,
  useElements,
  CardNumberElement
} from '@stripe/react-stripe-js';
import { setParkingData } from '@containers/ParkingInput/slice';

/**
  * @function
  * @name ConfirmPayment
  * @desc As a component it will just be "listener" when clientSecret(token used by stripe) is changed to call stripe and confirm payment.
  */
function ConfirmPayment(){

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();

  const { paymentData: { clientSecret, paymentMethodId } } = useSelector(state => state.parkingInput);  
  
  useEffect(() => {
    const confirmPayment = async ()  => {
      if (clientSecret && (paymentMethodId || elements?.getElement(CardNumberElement))) {
        const paymentMethod = {
          payment_method: paymentMethodId ? paymentMethodId 
          : { card: elements?.getElement(CardNumberElement) },
        }        
        const { error } = await stripe.confirmCardPayment(clientSecret, paymentMethod);

        if (error) {          
          dispatch(setParkingData({ isProcessing: false }));
        }
      }
    }
    confirmPayment();
  }, [clientSecret, elements, stripe, paymentMethodId]);

  return null;
}

export default ConfirmPayment;
