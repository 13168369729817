/**
  * @const drawingConfig
  * @desc Object that will hold drawing properties for areas on map like coloring, ...
  */
const drawingConfigArea = {
  strokeColor: "#FF9933",
  fillColor: "#FF9933",
  strokeOpacity: 1,
  strokeWeight: 1,
  fillOpacity: 0.8
};

const drawingConfigActiveArea = {
  strokeColor: "#3478F6",
  fillColor: "#3478F6",
  strokeOpacity: 1,
  strokeWeight: 1,
  fillOpacity: 0.8
};

export {
  drawingConfigArea,
  drawingConfigActiveArea
};
