import { useEffect } from "react";
import WheelPicker from "@components/WheelPicker";
import capitalize from "lodash/capitalize";
import { minutesIn } from "@utils/constants";
import debounce from "lodash/debounce";

/**
 * @component
 * @name DurationPicker
 * @desc single field input control
 * @param {String} label - field label
 * @param {Number} min - minumum value
 * @param {Number} max - maximum value
 * @param {Number} step - available options will be generated according to this property
 * @param {Object} dependent - object with props on whose values some of inner calculations are dependent on i.e. maxDuration
 * @param {Function} onChange - method that is executed on every option change event
 * @param {Object} field - Formik field property passed down from which we use the field name and current value
 */
const DurationPicker = ({
  label,
  min,
  max,
  step,
  dependent,
  onChange,
  disabled,
  field: { name, value },
}) => {
  // onChange should be fired only if user settled on value, stopped browsing
  const debouncedOnChange = debounce(async (fieldName, newValue) => {
    onChange(fieldName, newValue);
  }, 500);

  // Set maximum value if pricing has the related property
  if (dependent.maxDuration) {
    max = dependent.maxDuration / minutesIn[name];
  }

  if (name === "minutes") {
    // We should always use 15min step for minutes despite the pricing step
    step = 15;
    const maxHours = dependent.maxDuration ? dependent.maxDuration / 60 : 24;
    // Remove minutes picker if selected hours already exhausted available parking time
    max =
      dependent.parkingTime.hours === maxHours
        ? 0
        : // otherwise calculate remaining available minutes
        Math.floor(maxHours) - dependent.parkingTime.hours < 1
        ? dependent.maxDuration % 60
        : 59;
  }

  // Generate options for wheel picker component
  const options = [];
  // If only one option is available - pricing step is the same as maxParkingTime i.e. 2-week ticket
  if (min === max && min !== 0) {
    options.push({
      value: min * minutesIn[name],
      label: min.toString().padStart(2, "0"),
    });
  } else {
    // for all other cases
    for (let i = min; i <= max / step; i = i + 1) {
      const label =
        i * step >= min && i * step <= max
          ? (i * step).toString().padStart(2, "0")
          : "";
      options.push({ value: i * step, label });
    }
  }

  // Cleanup - cancel any running promise on unmounting
  useEffect(() => {
    return () => debouncedOnChange.cancel();
  }, [debouncedOnChange]);

  return (
    options.length > (min === 0 ? 1 : 0) && (
      <WheelPicker
        name={name}
        label={capitalize(label)}
        options={options}
        value={value || min}
        onChange={debouncedOnChange}
        disabled={disabled}
      />
    )
  );
};

export default DurationPicker;
