import styled from 'styled-components';
import ParkingAreaBar from './ParkingAreaBar';
import styles from './styles.module.css';

const ParkingAreaBarStyled = styled(ParkingAreaBar)`
  // Here we'll override default background color with provider color received from props
  div.${styles.container} {
    background-color: ${({ backgroundColor }) => backgroundColor };
  }
`;

export default ParkingAreaBarStyled
