const zoomToRadiusMap = {
  22: 1.2,
  21: 1.4,
  20: 1.6,
  19: 2,
  18: 3,
  17: 10,
  16: 12
};

/**
 * @description Function willtake zoom lvl and return one of radius(in meters) values or default value.
 * @param {number} zoomLvl 
 */
const getOptimalCircleRadius = (zoomLvl) => {
  return zoomToRadiusMap[zoomLvl] || 20;
};

export default getOptimalCircleRadius;