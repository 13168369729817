import { Formik, Form } from 'formik';
import { useSelector } from 'react-redux';

// Custom components
import PriceButton from './PriceButton';
import CalculatePrice from './CalculatePrice';
import SelectPricing from './SelectPricing';
import SelecteDuration from './SelectDuration';

function ParkingDuration({ slideIndex, setSlideIndex }){
  
  const { parkingTime } = useSelector(state => state.parkingInput);
  
  /**
    * This method will move slider to next slide (payment)
    */
  function onSubmit(){        
    setSlideIndex(slideIndex + 1);     
  }
  
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={parkingTime}>            
      <Form>
        <CalculatePrice/>
        <SelectPricing />
        <SelecteDuration />
        <PriceButton/>
      </Form>      
    </Formik>
  );
}

export default ParkingDuration;
