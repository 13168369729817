import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import IconSVG from '@components/IconSVG';

import classes from './styles.module.css';

/**
  * @function
  * @desc Component reponsible for rendering out text input. Input should be as part of bootstrap form.
  *
  * @param {Object} props.field - field is prop from Formik form, if passed it will override value and onChange
  */
function TextInput(props){

  let {
    id,
    type,
    size,    
    field, 
    form,   
    label,
    onIconClick,
    placeholder,
    children,
    element,
    icon
  } = props;

  const { t } = useTranslation();
  const inputRef = useRef(null);
  const [floating, setFloating] = useState(field.value);
  
  if(element){
    inputRef.current = element;
  }

  const handleFocus = el => {    
    setFloating(true); 
    if(!el.elementType) inputRef.current.focus();    
  }

  const handleBlur = el => {        
    setFloating(field.value);
    if(!el.elementType) form.setFieldTouched(field.name);
  }

  const ErrorFeedback = ({ message }) => {
    return <div className={`${classes.errorFeedback} invalid-feedback`}>{t(message)}</div>
  }

  const error = form.touched[field.name] && form.errors[field.name];
  
  return (
    <Form.Group className={`mb-3 ${classes.formGroup} ${error ? classes.hasError : ''}`}>
      <Form.Label className={`${classes.label} ${floating ? classes.floating : ''}`} 
        onClick={handleFocus}>
        { floating ? label || placeholder : placeholder || label }
      </Form.Label>
      { icon && <IconSVG name={icon} fill="var(--placeholder-color)" className={classes.icon} onClick={onIconClick}/>}
      { children ? React.cloneElement(children, { onBlur: handleBlur, onFocus: handleFocus }) 
      : <Form.Control
          ref={ref => inputRef.current = ref}
          id={id}
          type={type}
          size={size}                           
          className={`${classes.noBorder} ${error && 'is-invalid'}`}
          {...field}
          onBlur={handleBlur}  
          onFocus={handleFocus}  
          />
      }
      {error && <ErrorFeedback message={error} />}
    </Form.Group>
  );
}

TextInput.defaultProps = {
  size: 'lg',
  type: 'text',
  placeholder: '',
  onChange: null,
  field: {},
  onIconClick: null
};

export default TextInput;
