import { useTranslation } from "react-i18next";
import ReceiptActionForm from "./ReceiptActionForm";
import { useDispatch, useSelector } from "react-redux";
import Button from "@components/Button";

// Actions
import { setIsEditing as setIsEditingAction } from "../slice";
import { getAreaDetails as getAreaDetailsAction } from "@containers/Main/slice";
import { pricingTypeId } from "../../../utils/constants";

/**
 * @function
 *
 * @param {Boolean} isExpired - flag to indicate if ticket was stopped or if it ongoing
 */
function Footer({ isExpired }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const parkingTicket = useSelector((state) => state.parkingTicket);

  async function handleChangeParking() {
    let { parkingId } = parkingTicket.parkingArea;

    await dispatch(getAreaDetailsAction({ parkingId }));
    dispatch(setIsEditingAction(true));
  }

  if (isExpired) {
    return <ReceiptActionForm />;
  }

  // Enable ticket extend for standard/continuos pricings only
  if (
    [pricingTypeId.STANDARD, pricingTypeId.CONTINUOUS].includes(
      parkingTicket.pricingTypeId
    )
  ) {
    return (
      <div className="d-grid gap-2">
        {/* <Button text={t("Extend Parking")} onClick={handleChangeParking} disabled/> */}
      </div>
    );
  } else return null;
}

export default Footer;
