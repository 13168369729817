import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';


import styles from './styles.module.css';

const StyledInput = ({ onChange, isExpanded, flatBottomBorder, onBlur }) => {

  const { t } = useTranslation();      
  const [ value, setValue ] = useState('');

  const handleOnChange = ({ target }) => {    
    setValue(target.value);
    onChange(target.value);
  }

  const handleOnBlur = () => {    
    setValue('');    
    onBlur();    
  }

  const renderPlaceholder = () => {    
    return !value && isExpanded ? t('Find parking...') : '';
  }

  const inputClasses = [styles.search, flatBottomBorder && styles.flatBottomBorder];
  
  return (
    <>
      <Form.Control
        size={'lg'}
        id={'search'}
        type={'text'}        
        autoComplete="off"
        value={value}
        onChange={handleOnChange}
        className={inputClasses}
        placeholder={renderPlaceholder()}
        onFocus={handleOnChange}  
        onBlur={handleOnBlur}      
        />      
    </>
  );
};

export default StyledInput;
