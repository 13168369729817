import { createAsyncThunk } from '@reduxjs/toolkit';

import { googleAutocomplete } from '../googleAutocomplete';

/**
  * @function
  * @async
  * @desc This async action will fetch location from google.
  *
  * @param {String} searchQuery - keyword to search by
  */
const getParkings = createAsyncThunk(
  'keywordSearch/getGooglePlaces',
  async (keyword, thunkAPI) => {

    try {      
      const result = await googleAutocomplete(keyword);  
      if(!result) return [];            
      return result.map(
        ({ place_id, structured_formatting : { main_text, secondary_text }}) => ({ place_id, main_text, secondary_text })
      );      
    } catch (error) {
      console.log("Error keywordSearch/getGooglePlaces");
      console.log(error);
    }
  }
);

export default getParkings;
