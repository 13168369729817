import { useRef, useState } from 'react';
import { toast } from 'react-toastify';

/**
 * @function
 * @name useToastify
 * @desc hook that will expose methods for showing and dismissing of the toast messages
 * - it also provides method isVisible that can be used to determine if the instance of notification is already visible to prevent duplicates
  */
const useToastify = () => {  
  const ref = useRef(null);

  /** 
   * @function
   * @name notify
   * @param {String} title - optional paramater used for notification title
   * @param {String} message - optional parameter used for notification message
   * @param {String} type - optional parameter for setting type of notification (info, warning, error, success)
   * @param {Number} autoClose - optional parameter for setting the timeout of the notification
   * @returns {Object} - notification handling methods
   */
  const notify = ({ title, message, type = 'error', autoClose = 5000 }) => {  
    // Generate notification    
    const Title = () => title ? <div>{title}</div> : "";
    const Message = () => message ? <div>{message}</div> : "";
    const notification = <>
      <Title/>
      <Message/>
    </>;

    ref.current = toast(notification, { type, autoClose });
  }

  /**
   * @function
   * @name dismiss
   * @desc: hides notification on demand
   */
  const dismiss = () => toast.dismiss(ref.current);

  /**
   * @function
   * @name isVisible
   * @desc: returns state of the notification
   */
  const isVisible = () => toast.isActive(ref.current);

  return { notify, dismiss, isVisible };

}

export default useToastify;