// Custom components
import ZoneCode from '@components/ZoneCode';

// Import Styles
import styles from './styles.module.css';

function ParkingAreaBar({ logo, providerName, code, className }) {
  return (
    <div className={className}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <img src={logo} alt={providerName} />
          <ZoneCode>{code}</ZoneCode>
        </div>
      </div>
    </div>
  );
}

export default ParkingAreaBar;
