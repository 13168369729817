/** Custom components */
import IconSVG from '@components/IconSVG';

// Import Styles & Images
import styles from './styles.module.css';

/**
 * @name ConfirmPayment
 * @function
 * @description This is screen when user is redirected from app to confirm payment and go back to app.
 */
function ConfirmPayment() {

  return (
    <div className={styles.container}>
      <IconSVG name={'pointToBackArrow'} fill="var(--placeholder-color)" className={styles.icon}/>
      <div className={styles.card}>
        <div className={styles.text}>
          Payment transaction is finished. Please return to the app for the latest update on your payment.
        </div>
      </div>      
    </div>
  );
}

export default ConfirmPayment;
