import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

// Custom components
import CenterPin from '../CenterPin';
import renderMarkers from './renderMarkers';

// Styles, images, configs
import images from '@images';
import styles from './styles.module.css';
import {
  drawingConfigArea,
  drawingConfigActiveArea
} from './drawingConfig';

// Actions and async actions
import { setLastVisitedLocation } from '../slice';
import { setSelectedArea as setSelectedAreaAction } from '../slice';
import { getAreasByGeolocation } from '../slice';

// Utils
import { extractCoordinates } from '@utils/common';
import getOptimalCircleRadius from './utils/getOptimalCircleRadius';

// Turf for geoUtils
import circle from '@turf/circle';
import { point } from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';

const turf = {
  point,
  circle,
  booleanPointInPolygon
};

/**
  * @desc Container component that will hold map of our app.
  * TODO: Component is geting bulky so refactor should be done very soon.
  */
function Map({ setGoogleMapRef, center, setCenter }){

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

  const defaultMapProps = {
    zoom: 16,
    center: {
      lat: 59.318736328,
      lng: 18.045934438
    }
  };

  const defaultMapOptions = {
    fullscreenControl: false,
    zoomControl: false,
    styles: [],
    restriction: {
      strictBounds: true,
      latLngBounds: { north: 85, south: -85, west: -180, east: 180 }
    }
  };

  /** Ref data */
  let mapObj  = useRef(null);
  let mapsObj = useRef(null);
  let drawnAreas = useRef([]); // To keep track of areas that are already drawn on map

  /** State data */  
  let [ mapZoom, setMapZoom ] = useState(defaultMapProps.zoom);

  /** Redux data */
  let areas = useSelector((state) => state.main.areas);
  let selectedArea = useSelector((state) => state.main.selectedArea);
  let lastVisitedLocation = useSelector((state) => state.main.lastVisitedLocation);

  /**
    * @name useEffect
    * @desc method will be called when mapsObj refrence is ready.
    */
  useEffect(() => {    
    if(mapsObj.current && areas){
      /** Call draw areas and recall setAreaInCenter
        * NOTE: we do recal in case user is landing first time on page using link with lat, lng in it
        */
      drawAreas();
      setAreaInCenter(center);
    }
  }, [mapsObj.current, areas]);


  /**
    * @method
    * @desc Method will be called when map is ready for use.
    * Method will set map ref(Google API) so it can be used for other google related queries.
    * Also since this is first load we will check for query params to see if
    * location params are there and navigate map to those coordiantes.
    */
  function mapLoaded({ map, maps }){
    mapObj.current  = map;
    mapsObj.current = maps;
    setGoogleMapRef(map);

    const searchParams = new URLSearchParams(location.search);
    const lat = parseFloat(searchParams.get('lat'));
    const lng = parseFloat(searchParams.get('lng'));

    if(lat && lng){
      setCenter({ lat, lng });
    }
  }

  /**
    * @method
    * @desc
    */
  function setAreaInCenter(center){
   
    let centerPoint = turf.point([center.lng, center.lat]);
    let circleRadius = getOptimalCircleRadius(mapZoom);
    
    let areaInCenter = areas && areas.find(area => {
      if(area.locationPolygon.type === 'Point'){
       
        let circleCenter = area.centerPoint;
        let circlePolygon = turf.circle(circleCenter, circleRadius, {steps: 64, units: 'meters'});
        
        return turf.booleanPointInPolygon(centerPoint, circlePolygon);
      }

      return turf.booleanPointInPolygon(centerPoint, area.locationPolygon);
    });
    
    if(areaInCenter?.parkingId !== selectedArea?.parkingId){
      
      dispatch(setSelectedAreaAction(areaInCenter));

      /** Magnetic centering if new area is selected */
      if(areaInCenter){
        setCenter({
          lat: areaInCenter.centerPoint.coordinates[1],
          lng: areaInCenter.centerPoint.coordinates[0]
        });

        /** Find map shape of drawn area and change color and also change collor of previously selected area */
        let { shape } = drawnAreas.current.find((area) => area.parkingId === areaInCenter.parkingId);
        shape.setOptions({ ...drawingConfigActiveArea });
      }

      let shapeObj = drawnAreas.current.find((area) => area.parkingId === selectedArea?.parkingId);
      if(shapeObj){
        shapeObj.shape.setOptions({ ...drawingConfigArea });
      }
    }
  }

  /**
    * @method
    * @desc Method will be called each time areas are changed to draw areas on map.
    * If area is already drawn it will skip.
    */
  function drawAreas(){

    let circleRadius = getOptimalCircleRadius(mapZoom);

    areas.forEach( (area) => {
      
      let type = area.locationPolygon.type;
      let isAreaDrawn = drawnAreas.current.some((shape) => shape.parkingId === area.parkingId);

      /** If area is already drawn skip it but if area is drawn and is is a cricle redefine radius for it */
      if(isAreaDrawn && type === 'Point'){

        /** Find map shape of drawn area and change color and also change collor of previously selected area */
        let { shape } = drawnAreas.current.find((drawnArea) => drawnArea.parkingId === area.parkingId);
        shape.setOptions({ radius: circleRadius });
        return;
      }
      else if(isAreaDrawn){
        return;
      }
      
      let coordinates = extractCoordinates(area.locationPolygon);
     
      let shape;
      if(type === 'Polygon'){
        shape = new mapsObj.current.Polygon({
          paths: coordinates,
          ...drawingConfigArea
        });
      }
      else if(type === 'Point'){
        shape = new mapsObj.current.Circle({
          radius: circleRadius,
          center: coordinates,
          ...drawingConfigArea,
        });
      }

      shape.setMap(mapObj.current);
      drawnAreas.current.push({ shape, parkingId: area.parkingId });
    });
  }

  /**
    * @method
    * @desc Method will run each time center or zoom is changed on map, as well as first time map is loaded.
    */
  function onChange({ center, zoom, ...rest }){
    let radius = 3000;
    // We should recalculate the lat & lng in case user rotated the map on the axis which if values are not wrapped can excede range of -180 - 180
    const wrappedCenter = mapsObj.current ? new mapsObj.current.LatLng(center.lat, center.lng, false) : null;
    const wrappedLat = wrappedCenter ? wrappedCenter.lat() : center.lat;
    const wrappedlng = wrappedCenter ? wrappedCenter.lng() : center.lng;
    
    setMapZoom(zoom);
    setCenter(center);
    setAreaInCenter(center);

    /** Action dispatch */
    dispatch(setLastVisitedLocation({ lat: wrappedLat, lng: wrappedlng }));
    dispatch(getAreasByGeolocation({center: { lat: wrappedLat, lng: wrappedlng }, radius}));

    /** Update URL with location but only if map is loaded */
    if(mapObj.current){
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("lat", wrappedLat);
      searchParams.set("lng", wrappedlng);

      const urlSearchQuery = '?' + searchParams.toString();
      navigate(urlSearchQuery, { replace: false });
    }
  }

  return(
    <div className={styles.container}>
      <GoogleMapReact
        center={center}
        onChange={onChange}
        options={defaultMapOptions}
        onGoogleApiLoaded={mapLoaded}
        defaultZoom={defaultMapProps.zoom}
        defaultCenter={lastVisitedLocation || defaultMapProps.center}
        yesIWantToUseGoogleMapApiInternals
        bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY, libraries: ['places'] }}>

        {renderMarkers(areas, mapZoom, setCenter, selectedArea)}

      </GoogleMapReact>
      <CenterPin/>
    </div>
  );
}

export default Map;
