import './index.css';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

/** Custom components */
import ToastMessages from '@containers/ToastMessages';

/** Css imports */
import 'react-toastify/dist/ReactToastify.css';
import '@containers/ToastMessages/toastifyCssOverride.css';

// Initialize logging system (Sentry)
import './Logger/init';

/** Redux store */
import { store } from './store';  

let persistor = persistStore(store);

ReactDOM.render(
  
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ToastMessages />
        <BrowserRouter>        
            <App />
        </BrowserRouter>
      </PersistGate>
    </Provider>,
  
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
