/**
 * This method will extract preferred language for displaying pages from the browser settings
 * @returns language code that will i18 accept 'en', 'sv', ...
 */
const getBrowserLanguage = () => {
  const language = navigator.language || navigator.userLanguage;
  const [ code ] = language.split('-');
  return code;
}

export default getBrowserLanguage;