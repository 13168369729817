import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Navigation from '@routes';

// Initialize i18
import './i18/init.js';

function App() {
  return (
    <div className="App">
      <Navigation/>
    </div>
  );
}

export default App;
