import { createAsyncThunk } from '@reduxjs/toolkit';

/** Endpoints */
import GetAreasByGeolocation from '@endpoints/Parking/GetAreasByGeolocation';

/** Helpers */
import groupAndFormatAreaData from './helpers/groupAndFormatAreaData';

/**
  * @function
  * @async
  * @desc This is async thunk that receives center coordinates and radius to get all areas inside that circle.
  *
  * @param {Number} reqData.radius - radius of circle that will return areas
  * @param {Object} reqData.center - coordinate containing lat, lng
  */
const getAreasByGeolocation = createAsyncThunk(
  'main/getAreasByGeolocation',
  async (reqData, {rejectWithValue}) => {

    const { radius } = reqData;
    const { lat, lng } = reqData.center;

    try {

      let queryFilter = {
        Lat: lat,
        Long: lng,
        Radius: radius
      };

      let result = await GetAreasByGeolocation.get(queryFilter);

      const groupedAreas = groupAndFormatAreaData(result.data);
     
      /** Parsing data that are in String */
      // result.data = result.data?.map(area => {
      //   area.locationPolygon = JSON.parse(area.locationPolygon);
      //   area.centerPoint = JSON.parse(area.centerPoint);
      //   return area;
      // });

      return groupedAreas;

    } catch (error) {
      console.log("Error main/getAreasByGeolocation");
      console.log(error);
      return rejectWithValue([]);
    }
  }
);

export default getAreasByGeolocation;
