import styles from './styles.module.css';
import { escapeRegExp } from 'lodash';
import IconSVG from '@components/IconSVG';

const ProcessedText = ({text = '', highlight = ''}) => {
  const regex = new RegExp(`(${escapeRegExp(highlight)})`, 'gi')
  return (
    <>
      {
        text.split(regex).filter(part => part).map((part, i) => (
          regex.test(part) ? <b key={i}>{part}</b> : part
        ))
      }
    </>
  );
}

function ListItem({ onClick, item, keyword }){
  const mainText = item.place_id ? item.main_text : item.nameCode;
  const secondaryText = item.place_id ? item.secondary_text : item.name;
  const icon = item.place_id ? 'union' : 'parking';
  const handleOnClick = () => onClick(item);
  
  return (
    <div className={styles.listItemContainer} onClick={handleOnClick}>
      <IconSVG name={icon} className={styles.icon} fill={'gray'}/>
      <div className={styles.singleLineTruncated}>
        <div className={styles.mainText}><ProcessedText text={mainText} highlight={keyword}/></div>
        <div className={styles.secondaryText}><ProcessedText text={secondaryText} highlight={keyword}/></div>
      </div>
    </div>
  );
}

export default ListItem;
