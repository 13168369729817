import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';

import { setQuery, reset, getParkings, getGooglePlaces } from './slice';
import { getGooglePlaceDetails } from './googlePlaceDetails';

import StyledInput from './Input';
import CloseBtn from './CloseBtn';
import List from './List';
import styles from './styles.module.css';

const KeywordSearch = ({ googleMapRef, setMapCenter }) => {
  const dispatch = useDispatch();  

  const { searchQuery, isParkingsLoading, isGooglePlacesLoading, parkings = [], googlePlaces = []
  } = useSelector(state => state.keywordSearch);

  /** We need to enforce the following rules: 
   * 1. We must show up to 6 results max
   * 2. parkings have priority over google address search 
   * 3. but only up to three results if google search yields minimum 3
   * 4. if either search returns less than 3 the other one should fill the rest up to 6 max
   * */
  
  const mergeResults = () => {
    const totalResultNum = 6;
    const halfOfTotalResult = (totalResultNum / 2);
    const googlePlacesMaxResultNum = parkings.length >= halfOfTotalResult ? halfOfTotalResult : totalResultNum - parkings.length;
    const parkingsMaxResultNum = totalResultNum - googlePlacesMaxResultNum;    
    const parkingResult = [...parkings].splice(0, parkingsMaxResultNum);
    const googlePlacesResult = [...googlePlaces].splice(0, googlePlacesMaxResultNum);    
    return parkingResult.concat(googlePlacesResult);
  }

  const isLoading = isParkingsLoading || isGooglePlacesLoading;
  const results = mergeResults();
  const [ isInputActive, setIsInputActive ] = useState(false);
  
  const debouncedQuery = useCallback(debounce((value) => {        
    if(searchQuery !== value) dispatch(setQuery(value));    
  }, 500), [searchQuery]);

  const handleOnChange = (value) => {
    setIsInputActive(true);
    debouncedQuery(value.trim());
  }

  const handleReset = () => {
    dispatch(reset());    
  }

  const handleOnBlur = () => {
    setIsInputActive(false);
    debouncedQuery(null);
  }

  useEffect(() => {
    setIsInputActive(searchQuery !== null);
    if (!searchQuery) return;
    dispatch(getParkings({ searchQuery }));
    dispatch(getGooglePlaces(searchQuery));
  }, [searchQuery]);

  const handleOnItemClick = async item => { 
    handleReset();   
    let coordinates = {};
    
    if(item.place_id){
      const details = await getGooglePlaceDetails(googleMapRef, item.place_id);   
      coordinates = {
        lat: details.geometry.location.lat(),
        lng: details.geometry.location.lng()
      };
      
    } else {
      coordinates = { 
        lat: item.centerPoint.coordinates[1], 
        lng: item.centerPoint.coordinates[0]
      };      
    }     
    setMapCenter(coordinates);        
  }
  
  return (
    <div className={styles.container}>
      <StyledInput
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onReset={handleReset}
        isExpanded={isInputActive}
        flatBottomBorder={searchQuery}             
      />
      <List
        keyword={searchQuery}
        isLoading={isLoading}
        data={results}
        onItemClick={handleOnItemClick}
      />      
      <CloseBtn onClick={handleReset}/>
    </div>
  )
}

export default KeywordSearch;