import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import SimpleViewSlider from "react-view-slider/simple";
import { useTranslation } from "react-i18next";

import Logger from "@app/Logger";

// Custom Components
import UseAppMsg from './UseAppMsg';
import Modal from "@components/Modal";

import useToastify from "@containers/ToastMessages/useToastify";

// Slides with form
//import Payments from './Slides/Payments';
import CreditCard from "@app/PaymentProviders/Stripe";
import UserInfo from "./Slides/UserInfo";
import ParkingDuration from "./Slides/ParkingDuration";
import MultipleAreaSelect from "./MultipleAreaSelect";

// Actions
import { startParking as startParkingAction } from "./slice";
import { extendParking as extendParkingAction } from "./slice";
import { confirmParking as confirmParkingAction } from "./slice";
import { setParkingData as setParkingDataAction } from "./slice";
import { resetPricings as resetPricingsAction } from "@containers/Main/slice";
import { setIsEditing as setIsEditingAction } from "@containers/ParkingTicket/slice";

/** Constants */
import { PARKING_MANAGEMENT_TYPES } from '@utils/constants';

/**
 * @function
 * @desc Component will render out slider with all slides needed to start a parking.
 * UserInfo - slide to add initial info about user and vehicle
 * ParkingDuration - slide to set time for a parking
 * Payments - slide to choose what kind of payment will be used
 * CreditCard - slide to enter credit card data
 */
function ParkingInput({ show, setShowParkingInput }) {
  let dispatch = useDispatch();
  const { t } = useTranslation();
  const errorToast = useToastify();

  /** State data */
  let [slideIndex, setSlideIndex] = useState(0);

  /** Redux data */
  const { isEditing } = useSelector((state) => state.parkingTicket);
  let { pricing, ...parkingInputData } = useSelector(
    (state) => state.parkingInput
  );
  let selectedArea = useSelector((state) => state.main.selectedArea);
  let {
    isProcessing,
    paymentData: { chargeId, error: paymentError },
  } = parkingInputData;

  console.log("Parking input selectedArea");
  console.log(selectedArea);

  /** Navigation data */
  let [searchParams, setSearchParams] = useSearchParams();
  let sessionTag = searchParams.get("sessionTag");

  let headerTitleMap = {
    0: t("Your Information"),
    1: t("Parking Duration"),
    2: t("Payment Method"),
    3: t("Payment details"),
  };

  /**
   * @desc When chargeId is avaliable initiate confirm parking action
   */
  useEffect(() => {
    if (chargeId) {
      dispatch(confirmParkingAction({ chargeId }));
    }
  }, [chargeId]);

  /**
   * @desc
   */
  useEffect(() => {
    if (isEditing) {
      setSlideIndex(1);
    }
  }, [isEditing]);

  /**
   * @desc If payment fails display error message
   */
  useEffect(() => {
    if (paymentError) {
      errorToast.notify({ message: t(paymentError) });
    }
  }, [paymentError]);

  /**
   * @desc If isEditing flag is true and sessionTag is present in URL we are going to call extend parking, otherwise startParking
   * @param {String} paymentProvider - stripe or swish
   */
  function startExtendParking(paymentProvider, paymentMethodId) {
    const { pricingId, pricingTypeId } = pricing;
    const parkingId = selectedArea.parkingId;
    const payload = {
      pricingTypeId,
      pricingId,
      parkingId,
      sessionTag,
      paymentProvider,
      paymentMethodId,
      ...parkingInputData,
    };

    if (isEditing && sessionTag) {
      Logger.setAction("Extend parking");
      dispatch(extendParkingAction(payload));
    } else {
      Logger.setAction("Start parking");
      dispatch(startParkingAction(payload));
    }
  }

  /**
   * @method
   * @desc Method will render out slide for slider based on slideIndex.
   */
  function renderSlide() {
    if (slideIndex === 0) {
      return <UserInfo key={0} slideIndex={0} setSlideIndex={setSlideIndex} />;
    } else if (slideIndex === 1) {
      return (
        <ParkingDuration key={1} slideIndex={1} setSlideIndex={setSlideIndex} />
      );
    } /* Temporarily removed since atm we have only one type of payment provider
    else if(slideIndex === 2){
      return (
        <Payments
          key={2}
          slideIndex={2}
          isProcessing={isProcessing}
          startExtendParking={startExtendParking}
          setSlideIndex={setSlideIndex}/>
      );
    }*/ else if (slideIndex === 2) {
      return (
        <CreditCard
          key={2}
          slideIndex={2}
          isProcessing={isProcessing}
          startExtendParking={startExtendParking}
          setSlideIndex={setSlideIndex}
        />
      );
    }
  }

  /**
   * @method
   * @desc Method will hide parking input, set isEditing flag for ticket to false
   *       and reset some parkingInput props to initial values
   */
  function onHide() {
    setSlideIndex(0);
    setShowParkingInput(false);
    dispatch(setIsEditingAction(false));
    dispatch(
      setParkingDataAction({ pricing: null, parkingTime: {}, priceNet: 0, priceGross: 0 })
    );
    dispatch(resetPricingsAction());
  }

  /**
   * @method
   */
  function onBack() {
    setSlideIndex(slideIndex - 1);
  }

  /** GUARD */
  if (!selectedArea) return null;

  /**
   * @method
   */
  const renderSliderOrOther = () => {
    if(selectedArea.isGroup){
      return <MultipleAreaSelect selectedArea={selectedArea}/>;
    }
    
    if(selectedArea.managementType !== PARKING_MANAGEMENT_TYPES.MANUAL){
      return <UseAppMsg/>;
    }

    return <SimpleViewSlider>{renderSlide()}</SimpleViewSlider>;
  }

  return (
    <Modal
      show={show}
      onHide={onHide}
      title={headerTitleMap[slideIndex]}
      onBack={
        ((!isEditing && slideIndex > 0) || (isEditing && slideIndex > 1)) &&
        onBack
      }
    > 
      { renderSliderOrOther() }
      
    </Modal>
  );
}

ParkingInput.defaultProps = {
  show: false,
  setShowParkingInput: () => console.log("Function not set!!!"),
};

export default ParkingInput;
