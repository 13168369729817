import styles from './styles.module.css';
import IconSVG from '@components/IconSVG';

function CloseBtn({ onClick }){
  return (
    <div className={styles.container} onClick={onClick}>
      <IconSVG name={'cross'} fill="var(--placeholder-color)" className={styles.icon}/>
    </div>
  );
}

export default CloseBtn;
