import { createAsyncThunk } from '@reduxjs/toolkit';

/** Endpoints */
import GetParkingsBySearchQuery from '@endpoints/Parking/GetParkingsBySearchQuery';

/**
  * @function
  * @async
  * @desc This async action will fetch parkings from our DB
  * @param {String} searchQuery - keyword to search by
  */
const getParkings = createAsyncThunk(
  'keywordSearch/getParkingsBySearchQuery',
  async ({ searchQuery }, thunkAPI) => {

    const queryFilter = { searchQuery };
    
    try {      
      const result = await GetParkingsBySearchQuery.get(queryFilter);    
      return result.data;
    } catch (error) {
      console.log("Error keywordSearch/getParkingsBySearchQuery");
      console.log(error);
    }
  }
);

export default getParkings;
