import { useDispatch } from 'react-redux';

/** Styles */
import classes from './styles.module.css';

/** Actions */
import { setSelectedArea as setSelectedAreaAction, getAreaDetails } from '@containers/Main/slice';

function AreaItem({ areaData }) {

  /** Init */
  const dispatch = useDispatch();

  /** Props */
  const { name, address, nameCode, color } = areaData;
  
  /**
   * @method
   */
  const handleAreaClick = () => {
    console.log("Area click")
    dispatch(setSelectedAreaAction(areaData));
    dispatch(getAreaDetails({ parkingId: areaData.parkingId }));
  }
  
  return (
    <div className={classes.areaItemContainer} onClick={handleAreaClick}>
      <div className={classes.nameCodeContainer}>
        <div className={classes.nameCode} style={{ borderColor: color, backgroundColor: color }}>{nameCode}</div>
      </div>
      <div className={classes.content}>
        <div className={classes.name}>{name}</div>
        <div className={classes.address}>{address}</div>
      </div>      
    </div>
  );
} 

export default AreaItem;
