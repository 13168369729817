import styled from 'styled-components';

/**
  * @function
  * @desc Function will calculate height and width of pin based on zoom from map.
  */
const calculateSize = (mapZoom, dimensioType) => {
  let base = 0.25;

  if(dimensioType === 'width'){
    return (base * mapZoom) + 'vh';
  }

  return ((base + 0.05) * mapZoom) + 'vh';
}

const Wrapper = styled.div`
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -95%);
  width: ${({mapZoom}) => calculateSize(mapZoom, 'width')};
  height: ${({mapZoom}) => calculateSize(mapZoom, 'height')};
`;

export default Wrapper;
