import { current, createSlice } from "@reduxjs/toolkit";

/** Async thunks */
import getPrice from "./asyncThunks/getPrice";
import startParking from "./asyncThunks/startParking";
import extendParking from "./asyncThunks/extendParking";
import confirmParking from "./asyncThunks/confirmParking";

/**
 * @namespace
 */
const initialState = {
  email: "",
  licensePlate: "",
  phoneNumber: "",
  parkingTime: {},
  isProcessing: false,
  pricing: null,
  priceNet: 0,
  priceGross: 0,
  currency: "SEK",
  priceIsLoading: false,
  duration: 0,
  paymentData: {
    error: null,
    paymentProvider: null,
    chargeId: null,
    clientSecret: null, // Stripe
    paymentRequestToken: null, // Swish
  },
};

/**
 * @name parkingInputSlice
 */
export const parkingInputSlice = createSlice({
  name: "parkingInput",
  initialState,
  reducers: {
    setParkingData: (state, action) => {
      return { ...current(state), ...action.payload };
    }
  },
  extraReducers: (builder) => {
    /** getPrice */
    builder.addCase(getPrice.pending, (state, action) => {
      state.priceIsLoading = true;
    });
    builder.addCase(getPrice.fulfilled, (state, action) => {
      state.priceIsLoading = false;
      state.duration = action.payload.duration;
      state.currency = action.payload.currency;
      state.priceNet = action.payload.priceNet;
      state.priceGross = action.payload.priceGross;
    });
    builder.addCase(getPrice.rejected, (state, action) => {
      state.priceIsLoading = false;
      console.log("parkingInput/getPrice error in reducer");
      console.log(action);
    });

    /** Start parking */
    builder.addCase(startParking.pending, (state, action) => {
      state.isProcessing = true;
    });
    builder.addCase(startParking.fulfilled, (state, action) => {
      state.isProcessing = false;
      state.paymentData = action.payload;
    });
    builder.addCase(startParking.rejected, (state, action) => {
      state.isProcessing = false;
      console.log("parkingInput/startParking error in reducer");
      console.log(action);
    });

    /** Confirm Parking */
    builder.addCase(confirmParking.pending, (state, action) => {
      state.isProcessing = true;
    });
    builder.addCase(confirmParking.fulfilled, (state, action) => {
      state.paymentData = action.payload;
      state.isProcessing = false;
    });
    builder.addCase(confirmParking.rejected, (state, action) => {
      state.isProcessing = false;
      state.paymentData = action.payload;
    });

    /** Extend Parking */
    builder.addCase(extendParking.pending, (state, action) => {
      state.isProcessing = true;
    });
    builder.addCase(extendParking.fulfilled, (state, action) => {
      state.isProcessing = false;
      state.paymentData = action.payload;
    });
    builder.addCase(extendParking.rejected, (state, action) => {
      state.isProcessing = false;
      console.log("parkingInput/extendParking error in reducer");
      console.log(action);
    });
  },
});

/** Actions */
export const { setParkingData } = parkingInputSlice.actions;
/** Async actions */
export { getPrice, startParking, extendParking, confirmParking };
/** Reducer */
export default parkingInputSlice.reducer;
