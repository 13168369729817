import { useSelector } from 'react-redux';

import AnimatedLogo from '@components/AnimatedLogo';

import classes from './styles.module.css';

const Loader = () => {
  const { initialAreasLoaded } = useSelector(state => state.main);
  const paymentIsProcessing = useSelector(state => state.parkingInput.isProcessing);
  const parkingTicketIsLoading = useSelector(state => state.parkingTicket.isLoading || state.parkingTicket.isEmailSending);

  if(initialAreasLoaded && !paymentIsProcessing && !parkingTicketIsLoading){
    return null;
  } else return <div className={classes.container}><AnimatedLogo /></div>
}

export default Loader;
