import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import forOwn from "lodash/forOwn";
import capitalize from "lodash/capitalize";
import { minutesIn, pricingUnitCfg, pricingTypeId } from "@utils/constants";

import { setParkingData as setParkingDataAction } from "../../../slice";

import classes from "./styles.module.css";

/**
 * @component
 * @name SelectPricing
 * @desc This component enables user to select one of multiple pricings
 */
const SelectPricing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [activeId, setActiveId] = useState(null);
  const { isEditing } = useSelector((state) => state.parkingTicket);
  const pricings = useSelector((state) => state.main.selectedArea.pricings);
  const selectedPricing = useSelector((state) => state.parkingInput.pricing);
  const { resetForm } = useFormikContext();

  const reduceToIds = (availablePricings) => {
    // Show pricing types by this order
    const order = [
      pricingTypeId.STANDARD,
      pricingTypeId.CONTINUOUS,
      pricingTypeId.DAYS,
      pricingTypeId.WEEKS,
      pricingTypeId.MONTHS,
    ];
    return availablePricings
      .reduce((acc, { pricingTypeId }) => {
        // Note: for now we'll show only one pricing of the same type
        // - this will probably change in the future when we define how to handle multiple pricing of the same type.
        // - at the moment we will give priority to the first one in the row.
        return !acc.find((e) => e === pricingTypeId)
          ? [...acc, pricingTypeId]
          : acc;
      }, [])
      .sort((a, b) => order.indexOf(a) - order.indexOf(b));
  };

  // Accumulate available pricing type IDs
  let pricingTypesIds;
  // Case for starting of the parking ticket (all available pricings)
  if (!isEditing) {
    pricingTypesIds = pricings ? reduceToIds(pricings) : [];
  } else {
    // Case for extending of the ticket (only Standard pricing)
    pricingTypesIds = [pricingTypeId.STANDARD];
  }

  const handleSelect = ({ target }) =>
    setActiveId(Number(target.getAttribute("pricingtypeid")));

  useEffect(() => {
    if (!activeId || activeId === selectedPricing?.pricingTypeId) {
      // Set previously selected pricing or fallback to first of available pricings
      setActiveId(selectedPricing?.pricingTypeId || pricingTypesIds[0]);
      return null;
    }

    const pricing = pricings.find((p) => p.pricingTypeId === activeId);

    // reset parking time on pricing change
    const parkingTime = {};
    forOwn(pricingUnitCfg[activeId].units, ({ name, min }) => {
      const step = pricing.pricingStep / minutesIn[name];
      parkingTime[name] = min > 0 && step > min ? step : min;
    });

    const updatedParkingData = {
      pricing: {
        ...pricing,
        ...pricingUnitCfg[activeId],
      },
      parkingTime,
    };

    dispatch(setParkingDataAction(updatedParkingData));

    // Reset form with new initial values
    resetForm({ values: parkingTime });

    // Left as help for QA
    console.log("SELECTED PRICING:", updatedParkingData.pricing);
  }, [activeId]);

  return pricingTypesIds.length > 1 ? (
    <Container className={`${classes.pricingsContainer}`}>
      <Row>
        {pricingTypesIds.map((id, idx) => {
          return (
            <Col
              className={`${classes.pricingTab} ${
                id === activeId && classes.active
              }`}
              onClick={handleSelect}
              pricingtypeid={id}
              key={idx}
            >
              {capitalize(t(pricingUnitCfg[id].label))}
            </Col>
          );
        })}
      </Row>
    </Container>
  ) : null;
};

export default SelectPricing;
