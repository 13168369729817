import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

// Import Styles & Images
import styles from './styles.module.css';
import images from '@images';
import IconSVG from '@components/IconSVG';

import Button from './Button';
import MiddleButton from './MiddleButton';


/** Actions */
import { getAreaDetails } from '@containers/Main/slice';
import { stopParking as stopParkingAction } from '@containers/ParkingTicket/slice';
import { toggleMenu } from '@containers/Menu/slice';

function MapToolbar({ showParkingInput, setShowParkingInput }) {

  const dispatch = useDispatch();

  /** Redux data */
  let selectedArea = useSelector((state) => state.main.selectedArea);
  let {
    sessionId,
    sessionTag,
    endDatetime
  } = useSelector((state) => state.parkingTicket);

  /** Flags */
  let disableMiddleButton = (!selectedArea || showParkingInput) ? true : false;
  let parkingIsExpired = moment.utc(endDatetime).isBefore(moment());
  let parkingIsOngoing = sessionId && !parkingIsExpired;

  /**
   * @method
   * @desc Method will fetch details for parking area and set showParkingInput flag to true.
   */
  function middleButtonClick(){
    let { parkingId } = selectedArea;

    if(parkingIsOngoing){
      dispatch(stopParkingAction({ sessionTag }));
      return;
    }

    dispatch(getAreaDetails({ parkingId }));
    setShowParkingInput(true);
  }

  function handleShowMenu() {
    dispatch(toggleMenu());
  }

  return (
    <div className={styles.container}>
      {/* Left Button */}
      <Button>
        <img src={images.logoVertical} className={styles.logo}/>
      </Button>

      {/* Middle Button */}
      <MiddleButton
        onClick={middleButtonClick}
        disabled={disableMiddleButton}
        parkingIsOngoing={parkingIsOngoing}/>

      {/* Right Button */}
      <Button onClick={handleShowMenu}>        
        <IconSVG name={'menu'} fill="white" className={styles.icon}/>                  
      </Button>
    </div>
  );
}

export default MapToolbar;
