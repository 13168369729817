import { createSlice } from '@reduxjs/toolkit';

/**
  * @namespace
  * @property {Bool}  isVisible - show/hide language input modal  
  */

const initialState = {
 isVisible: false
};

/**
  * @name languageInputSlice
  * @desc This reducer is responsible for storing languageInput related props  
  */
export const languageInputSlice = createSlice({
  name: 'languageInput',
  initialState,
  reducers: {
    toggleLanguageModal: state => {
      state.isVisible = !state.isVisible
    }
  }  
});

/** Actions */
export const { toggleLanguageModal } = languageInputSlice.actions;
/** Reducer */
export default languageInputSlice.reducer;
