import moment from 'moment';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import Footer from './Footer';
import Modal from '@components/Modal';
import TimeAndCost from './TimeAndCost';

// Utils
import { extractCoordinates } from '@utils/common';

// Actions
import { getSessionDetails as getSessionDetailsAction } from './slice';

import styles from './styles.module.css';

function ParkingTicket({ setMapCenter }){

  let dispatch = useDispatch();
  const { t } = useTranslation();

  /** Navigation data */
  let [ searchParams, setSearchParams ] = useSearchParams();
  let sessionTag = searchParams.get('sessionTag');

  /** Redux data */
  const {
    isEditing,
    isLoading,
    endDatetime,
    parkingArea
  } = useSelector(state => state.parkingTicket);
  let selectedArea = useSelector((state) => state.main.selectedArea);

  useEffect(() => {
    if(sessionTag){
      dispatch(getSessionDetailsAction(sessionTag));
    }
  }, [sessionTag]);

  /** When ticket is loaded, animate map to center of area */
  useEffect(() => {
    if(parkingArea){
      let { lat, lng } = extractCoordinates(parkingArea.centerPoint);
      setMapCenter({ lat, lng });
    }
  }, [ parkingArea ]);

  // Check if ticker expired or if it is ongoing
  let isExpired = moment.utc(endDatetime).isBefore(moment());

  /**
    * @desc Method will remove sessionTag from searchQuery and thus close modal.
    */
  function onHide(){
    searchParams.delete('sessionTag');
    let newSearchQuery = searchParams.toString();
    setSearchParams(newSearchQuery);
  }

  if(!sessionTag || !selectedArea){
    return null;
  }

  return (
    <Modal
      onHide={onHide}
      show={!isEditing}
      showCloseButton={isExpired}
      isLoading={isLoading || !selectedArea}
      title={isExpired ? t('Stopped Parking') : t('Ongoing Parking')}>

      <Header/>
      <div className={styles.divider}/>
      <div className={styles.contentContainer}>
        <TimeAndCost/>
        <Footer isExpired={isExpired}/>
      </div>

    </Modal>
  );
}

export default ParkingTicket;
