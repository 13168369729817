import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import getSymbolFromCurrency from 'currency-symbol-map';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import moment from 'moment';

import Label from '../Label';
import styles from './styles.module.css';

import { formatAmount } from '@utils/common/formatAmount';

function TimeAndCost(){

  const { t } = useTranslation();

  /** Redux data */
  const {
    charges,
    isLoading,
    endDatetime,
    startDatetime
  } = useSelector(state => state.parkingTicket);

  console.log("These are charges on ticket")
  console.log(charges);

  const priceNet = charges && charges.reduce((total, charge) => { return total + charge.priceNet }, 0);
  const priceGross = charges && charges.reduce((total, charge) => { return total + charge.priceGross }, 0);
  const priceVat = priceGross - priceNet;

  const startTimeDisplay = moment(startDatetime).format('HH:mm');
  const startDateDisplay = moment(startDatetime).format('MMM D');
  const endTimeDisplay = moment(endDatetime).format('HH:mm');
  const endDateDisplay = moment(endDatetime).format('MMM D');
  const parkingIsExpired = moment.utc(endDatetime).isBefore(moment());

  // TODO - collect this info from BE once available (country where parking is located)
  const countryCode = 'SV';

  return (
    <Container>
      <Row className={styles.row}>
        
        <Col>
          <Label>{t('Start')}</Label>
          {
            isLoading ? 
            <Spinner animation="border"/> :
            <>
              <div className={styles.mainText}>{startTimeDisplay}</div>
              <div>{startDateDisplay}</div>
            </>
          }
        </Col>

        <Col>
          <Label>{t('End')}</Label>
          {
            isLoading ? 
            <Spinner animation="border"/> :
            <>
              <div className={styles.mainText}>{endTimeDisplay}</div>
              <div>{endDateDisplay}</div>
            </>
          }
        </Col>

        <Col>
          <Label>{parkingIsExpired ? t('Total cost') : t('Est. cost')}</Label>
          {
            charges.length &&
            <>
              <div className={styles.mainText}>{formatAmount(Math.round(priceGross * 100) / 100, charges[0].currency, countryCode)}</div>
              <div>{t('VAT')} {formatAmount(Math.round(priceVat * 100) / 100, charges[0].currency, countryCode)}</div>
            </>
          }
        </Col>
      </Row>
    </Container>
  );
}

export default TimeAndCost;
