import Api from '../../Api';

class GetAreasByGeolocation extends Api {

  constructor(){
    super('/Parking/GetAreasByGeolocation');
  }

}

export default new GetAreasByGeolocation();
