import Api from '../../Api';

class GetPrice extends Api {

  constructor(){
    super('/Pricing/GetPrice');
  }

}

export default new GetPrice();
