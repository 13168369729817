import { ToastContainer, Slide } from 'react-toastify';
import CustomCloseButton from './CustomCloseButton';

/**
  * @function
  * @name ToastMessages
  * @desc Main module for toast messages in app. Here toast container will be configured.
  * 
  * Usage:
  * To use toast messages use hook useToastify which will provide 'notify' and 'dismiss' methods and isVisible flag.
  * Note: every usage of hook will create new instance of the toast wrapper.
  * i.e.:
  * 1. instantiate: const { notify, dismiss, isVisible } = useToastify();
  * 2. show notification: notify({ title: 'Message title', message: 'Message body', type:'warning', autoclose: 1000 });
  * 3. hide notification: dismiss();
  * 
  * For more documentation visit: https://github.com/fkhadra/react-toastify
  */

const ToastMessages = () => {
  return (
    <ToastContainer
      icon={false}
      theme="colored"
      position="top-center"
      transition={Slide}
      hideProgressBar={true}
      closeButton={CustomCloseButton}
    />
  );
};

export default ToastMessages;
