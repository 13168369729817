//import Storage from '@utils/Storage';
import serializeQueryString from "./serializeQueryString";
import { handleError } from "@containers/Error";
import i18n from "i18next";

/**
 * @class
 * @name Api
 * @desc
 *
 * NOTE:
 */
class Api {
  constructor(path = "/v1/api/ping", host = process.env.REACT_APP_API_HOST) {
    this.host = host;
    this.path = path;
  }

  /**
   * @method
   * @name getHeaders
   * @desc Method will return all necessary headers for api calls.
   */
  getHeaders = () => {
    //const token = Storage.getItem("token") || '';

    return {
      "Accept-Language": i18n.language,
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-App-Type": "web-app",
      //'Authorization': `Bearer ${token}`
    };
  };

  /**
   * @method
   * @name fullUrl
   * @desc Method will assemble full url, taking into account host, path and queryFilter
   */
  fullUrl = (queryFilter) => {
    const queryParams = serializeQueryString(queryFilter);
    return `${this.host}${this.path}${queryParams}`;
  };

  /**
   * @method
   * @name get
   * @desc
   *
   * @param {Object} queryFilter - object containing filtering params to add to path and make URL
   */
  get = async (queryFilter) => {
    return this.apiCall({
      method: "GET",
      headers: this.getHeaders(),
      url: this.fullUrl(queryFilter),
    });
  };

  /**
   * @method
   * @name post
   * @desc
   *
   * @param {Object} queryFilter - object containing filtering params to add to path and make URL
   * @param {Object} data - data object that will make body of our request
   */
  post = async (queryFilter, data) => {
    return this.apiCall({
      method: "POST",
      headers: this.getHeaders(),
      body: JSON.stringify(data),
      url: this.fullUrl(queryFilter),
    });
  };

  /**
   * @method
   * @name apiCall
   * @desc Last method in chain, it will make actual http call, proces request options and process response.
   *
   * @param {Object} options - object containing all data for api call
   * @param {Object} options.url - full url for request
   * @param {Object} options.method - method to be executed (GET, PATCH, PUT, DELETE)
   * @param {Object} options.headers - headers for request
   */
  apiCall = async (options) => {
    let { url, headers, method } = options;

    try {
      const response = await fetch(url, options);
      const { status, statusText, ok } = response;

      /** Unpack promiss for response content */
      const data = await this.processResponseBody(response);

      /** If there is an error process it */
      if (!ok) {
        return this.processError(status, statusText, options, data);
      }

      return data;
    } catch (error) {
      throw error;
    }
  };

  /**
   * @method
   * @name processResponseBody
   * @desc Since sometimes body in response is missing this method will check for body and process it.
   * @param {Object} response -  whole response from API
   * @return {Object} - unpacked response should be returned to caller.
   */
  processResponseBody = async (response) => {
    try {
      return await response.json();
    } catch (error) {
      return null;
    }
  };

  /**
   * @method
   * @name processError
   * @desc Method will be used to process any error comming from apiCall method.
   *
   * @param {number} status - http status(5xx, 4xx)
   * @param {string} statusText - error message returned from server i.e. Bad Request
   * @param {object} options - request options (headers, method, ...)
   * @param {number} response - response data containing error
   */
  processError = (status, statusText, options, data) => {
    const error = {
      message: statusText,
      context: {
        request: {
          options,
          response: {
            status,
            data,
          },
        },
      },
    };

    handleError(error);

    if (status === 401) {
      Storage.clear();
      window.location.href = "/auth";
      // TODO: toast(message, { type: 'error' });
    }

    throw data;
  };
}

export default Api;
