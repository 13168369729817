import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";

import { formatAmount } from "@utils/common/formatAmount";

import Button from "@components/Button";
import styles from "./styles.module.css";

function PriceButton() {
  const { t } = useTranslation();

  // TODO - collect this info from BE once available (country where parking is located)
  const countryCode = "SV";

  /** Redux data */
  const { currency, priceGross, priceIsLoading, duration } = useSelector(
    (state) => state.parkingInput
  );

  const Price = () => {
    if (priceIsLoading) {
      return <Spinner animation="border" variant="dark" size="sm" />;
    } else {
      return formatAmount(
        Math.round(priceGross * 100) / 100,
        currency,
        countryCode
      );
    }
  };

  {
    /* TODO - TEMPORARY solution
     * remove priceGross disabled condition once we get final business descision
     * regarding prices smaller than Stripe accepts (0.50$ or 6SEK )
     */
  }
  const isNextButtonDisabled = !duration || priceIsLoading;

  return (
    <div className={styles.priceButton}>
      <Container>
        <Row>
          <Col xs={4} className={styles.priceContainer}>
            <Price />
          </Col>
          <Col xs={8} className={styles.priceButtonContainer}>
            <Button
              disabled={isNextButtonDisabled}
              text={t("Next")}
              type="submit"
              className={styles.button}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PriceButton;
