import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CreditCardForm from './CreditCardForm';
import ConfirmPayment from './ConfirmPayment';

const publishableKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(publishableKey);

function CreditCard(props) {

  let { isProcessing, startExtendParking } = props;

  /**
    * @method
    * @desc Method is called when all informations for credit card are added.
    * Then startExtendParking from parent is called to initiate parking.
    * paymentMethodId - ID of payment method obtained from Google Pay
    */
  function onSubmitHandler(paymentMethodId){
    const paymentProvider = 'stripe';
    startExtendParking(paymentProvider, paymentMethodId);
  }

  return (
    <Elements stripe={stripePromise}>
      <div className="d-grid gap-2">
        <CreditCardForm
          isProcessing={isProcessing}
          onSubmitHandler={onSubmitHandler}/>
        <ConfirmPayment/>
      </div>
    </Elements>
  );
}

export default CreditCard;
