function formValidation({ email }) {
  let errors = {};
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  if(!emailRegex.test(email)){
    errors.email = "Invalid Email";
  }
  
  return errors;
}

export default formValidation;
