import { createAsyncThunk } from "@reduxjs/toolkit";

/** Endpoints */
import { default as StripeCreatePaymentIntent } from "@endpoints/Stripe/CreatePaymentIntent";
import { default as SwishCreatePaymentIntent } from "@endpoints/Swish/CreatePaymentIntent";

/**
 * @function
 * @async
 * @desc This asyncaction will take all parking data, payment provider type that is choosen and initiate start parking on backend.
 */
const startParking = createAsyncThunk(
  "parkingInput/startParking",
  async (
    { paymentProvider, paymentMethodId, ...payload },
    { rejectWithValue }
  ) => {
    const paymentEndpoint = {
      swish: SwishCreatePaymentIntent,
      stripe: StripeCreatePaymentIntent,
    }[paymentProvider];

    const {
      parkingId,
      pricingId,
      pricingTypeId,
      phoneNumber,
      licensePlate,
      duration,
      email,
    } = payload;
    const body = {
      email,
      duration,
      parkingId,
      pricingId,
      pricingTypeId,
      phoneNumber,
      licensePlate,
    };

    try {
      let result = await paymentEndpoint.post(null, body);
      return { ...result.data, paymentMethodId };
    } catch (error) {
      console.log("Error parkingInput/getPrice");
      console.log(error);
      rejectWithValue(error);
    }
  }
);

export default startParking;
