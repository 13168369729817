import { useState, useEffect } from 'react';

/** Endpoints */
import EmailVerification from '@endpoints/Authentication/EmailVerification';

function useVerifyEmail(email, token) {

  /** State */
  const [ result, setResult ] = useState(null);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    if(email && token){
      console.log("Verificiraj email", email, token)
      verifyEmail();
    }
  }, [email, token]);

  /**
   * @method
   * @description
   */
  const verifyEmail = async () => {
    setIsLoading(true);

    try {
      const queryParams = {
        email,
        token
      };

      const emailVerificationResult = await EmailVerification.get(queryParams);
      console.log("Results for emial verification");
      console.log(emailVerificationResult);
      setResult(emailVerificationResult.data);

    } catch (error) {
      console.log("Error on verification email:");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  return { 
    result,
    isLoading 
  };
}

export default useVerifyEmail;