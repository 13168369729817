/** Containers */
import Main from '@containers/Main';

function MainScreen(){
  return(
    <div>
      <Main/>
    </div>
  );
}

export default MainScreen;
