import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

import TextInput from '../../components/TextInput';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { formatAmount } from '@utils/common/formatAmount';

import {
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
  useStripe
} from '@stripe/react-stripe-js';

import Button from '@components/Button';
import PaymentProviderButton from './PaymentProviderButton';

import elementOptions from './options';

import classes from './styles.module.css';

function CreditCardForm({ onSubmitHandler, isProcessing }) {
  const stripe = useStripe();
  const { priceGross, currency } = useSelector(state => state.parkingInput);

  const { t } = useTranslation();
  const elements = useElements();
  const [_, forceReload] = useState();
  const [touched, setTouched] = useState({});
  const [errors, setErrors] = useState({});
  const [values, setValues] = useState({});
  const [complete, setComplete] = useState({});

  const changeHandler = ({ elementType, empty, complete, error }) => {
    setValues({ ...values, [elementType]: !empty});
    setTouched({ ...touched, [elementType]: true});
    setErrors({ ...errors, [elementType]: error?.message});
    setComplete({ ...values, [elementType]: complete});
  }

  const isValid = !Object.keys(complete).filter(key => complete[key] === false).length
    && !Object.keys(errors).filter(key => errors[key]).length
    && Object.keys(values).filter(key => values[key]).length === 3;

  useEffect(() => {
    return () => forceReload(null);
  }, [elements]);


  if(!stripe || !elements) return null;

  return (
    <div className={classes.formContainer}>
      <TextInput form={{touched, errors}} field={{name: 'cardNumber', value: values.cardNumber}} element={elements.getElement('cardNumber')} placeholder={t('Card number')}
        icon='creditCard'>
        <CardNumberElement options={{ ...elementOptions, placeholder: '' }}
          onChange={changeHandler}
          className={`${classes.cardElementWrapper} ${errors.cardNumber ? classes.hasError : ''}`}
        />
      </TextInput>

      <TextInput form={{touched, errors}} field={{name: 'cardExpiry', value: values.cardExpiry }} element={elements.getElement('cardExpiry')}
        placeholder={t('Expiration date')} label={t('MM/YY')}>
        <CardExpiryElement options={{ ...elementOptions, placeholder: '' }}
          onChange={changeHandler}
          className={`${classes.cardElementWrapper} ${errors.cardNumber ? classes.hasError : ''}`}
        />
      </TextInput>

      <TextInput form={{touched, errors}} field={{name: 'cardCvc', value: values.cardCvc}} element={elements.getElement('cardCvc')} placeholder={t('CVC')}>
        <CardCvcElement options={{ ...elementOptions, placeholder: '' }}
          onChange={changeHandler}
          className={`${classes.cardElementWrapper} ${errors.cardNumber ? classes.hasError : ''}`}
        />
      </TextInput>

      <Row>
        <Col>
          <div className={classes.noteContainer}>
            {t('purchase_ticket_msg')}
          </div>
        </Col>
      </Row>

      <Row className={classes.actionRow}>
      {/* Google/Apple Pay buttons */}
        <PaymentProviderButton price={priceGross} currency={currency} onSubmit={onSubmitHandler}/>
        <Col>
          <Button
            text={`${t('Pay')} ${formatAmount(priceGross, currency)}`}
            disabled={isProcessing || !isValid}
            onClick={() => isValid && onSubmitHandler()}
            className={classes.button}/>
        </Col>
      </Row>
    </div>

  );
}

export default CreditCardForm;
