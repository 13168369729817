/**
  * @function
  * @desc Method will receive mapRef(actual map html element) and id of place that we want to get details for.
  *
  * @return {Promise} - promise is returned
  */
export const getGooglePlaceDetails = async (mapRef, placeId) => {

  return new Promise((resolve, reject) => {

    var request = {
      placeId: placeId,
      fields: ['name', 'rating', 'formatted_phone_number', 'geometry']
    };

    let service = new window.google.maps.places.PlacesService(mapRef);
    service.getDetails(request, resolve);
  })
}
