import React from 'react';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import {    
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";

/** Sentry Init */
Sentry.init({
  dsn: 'https://e16049c8860844c6a7c39f56bd372e4b@sentry2.epti.com/23',
  integrations: [new BrowserTracing({
    routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
    ),
})],
  environment: process.env.REACT_APP_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
  normalizeMaxBreadth: 10
});

export default Sentry;