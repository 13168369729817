/**
  * @function
  * @name
  * @desc Function will take in object with properties, loop through it and make query param string.
  * For now function will only consider first lvl so no deep lvls can be processed.
  *
  * @param {Object} queryFilter - object containing properties
  */
function serializeQueryString(queryFilter){

  if(!queryFilter){
    return '';
  }

  let queryStr = [];
  for(let key in queryFilter){

    let value = queryFilter[key];
    let param = `${key}=${encodeURIComponent(value)}`

    queryStr.push(param);
  }

  return (queryStr.length > 0) ? `?${queryStr.join("&")}` : '';
}

export default serializeQueryString;
