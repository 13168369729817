import Marker from './Marker';

/**
  * @function
  * @desc It is called as a function rather than component, for some reason as component it fails to render out pins.
  *
  * @param {Array} areas - areas on map, foreach of them we assing one marker
  * @param {Number} mapZoom - current map zoom
  * @param {function} setCenter - function to set map center
  * @param {Object} selectedArea - areathat is currently selected on map
  */
function renderMarkers(areas, mapZoom, setCenter, selectedArea){

  if(mapZoom < 9 || !areas){
    return null;
  }

  return areas.map((area) => {

    if(area.parkingId === selectedArea?.parkingId){
      return null;
    }

    return (
      <Marker
        mapZoom={mapZoom}
        key={area.parkingId}
        lat={area.centerPoint.coordinates[1]}
        lng={area.centerPoint.coordinates[0]}
        onClick={() => setCenter({
          lat: area.centerPoint.coordinates[1],
          lng: area.centerPoint.coordinates[0]
        })}
      />
    );
  })
}

export default renderMarkers;
