import { createSlice } from '@reduxjs/toolkit';

/** Async thunks */
import stopParking from './asyncThunks/stopParking';
import getSessionDetails from './asyncThunks/getSessionDetails';
import sendReceiptToEmail from './asyncThunks/sendReceiptToEmail';

/**
  * @namespace
  * parkingArea: null,
  * @param {boolean} isEditing - flag to indicate if we want to edit(extend) current ticket
  */
const initialState = {
  sessionId: null,
  isLoading: false,
  licensePlate: "",
  code: "",
  startDatetime: "",
  endDatetime: "",
  currency: "",
  charges: [],
  name: "",
  parkingArea: null,
  isEmailSending: null,
  emailSent: null,
  isEditing: false
};

/**
  * @name parkingInputSlice
  */
export const parkingTicketSlice = createSlice({
  name: 'parkingTicket',
  initialState,
  reducers: {
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    }
  },
  extraReducers: (builder) => {
    /** Session details */
    builder.addCase(getSessionDetails.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getSessionDetails.fulfilled, (state, action) => {
      return {...action.payload, isLoading: false};
    });
    builder.addCase(getSessionDetails.rejected, (state, action) => {
      state.isLoading = false;
    });
    /** Send receipt to Email */
    builder.addCase(sendReceiptToEmail.pending, (state, action) => {
      state.isEmailSending = true;
    });
    builder.addCase(sendReceiptToEmail.fulfilled, (state, action) => {
     state.isEmailSending = false;
     state.emailSent = true;
    });
    builder.addCase(sendReceiptToEmail.rejected, (state, action) => {
      state.isEmailSending = false;
    });

    /** Stop parking */
    builder.addCase(stopParking.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(stopParking.fulfilled, (state, action) => {
      state.isLoading = false;
      state.endDatetime = action.payload.endDatetime;
    });
  }
});

export { getSessionDetails, sendReceiptToEmail, stopParking };
export default parkingTicketSlice.reducer;
export const { setIsEditing } = parkingTicketSlice.actions;
