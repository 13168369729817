import { createSlice } from '@reduxjs/toolkit';

/** Async thunks */
import getAreaDetails from './asyncThunks/getAreaDetails';
import getAreasByGeolocation from './asyncThunks/getAreasByGeolocation';

/**
  * @namespace
  * @property {Array}  areas - list of parking areas
  * @property {Object} selectedArea - areas selected for parking
  * @property {Bool}   areasLoading - if areas are fetched from API
  * @property {Object} lastVisitedLocation - once map on change is finshed we store that value
  */
const initialState = {
  areas: [],
  selectedArea: null,
  areasLoading: false,
  initialAreasLoaded: false,
  lastVisitedLocation: null
};

/**
  * @name mainSlice
  * @desc This is reducer responsible for storing parking areas, parking area that is selected by a user.
  * TODO: clean up extra reducer to be more readable
  */
export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setSelectedArea: (state, action) => {
      state.selectedArea = action.payload;
    },
    resetPricings: (state, action) => {      
      state.selectedArea.pricings = null;
    },
    setLastVisitedLocation: (state, action) => {
      state.lastVisitedLocation = action.payload;
    }
  },
  extraReducers: (builder) => {

    /** getAreasByGeolocation */
    builder.addCase(getAreasByGeolocation.pending, (state, action) => {
      state.areasLoading = true;
    });
    builder.addCase(getAreasByGeolocation.fulfilled, (state, action) => {
      state.areas = action.payload;
      state.initialAreasLoaded = true;
      state.areasLoading = false;
    });
    builder.addCase(getAreasByGeolocation.rejected, (state, action) => {
      state.initialAreasLoaded = true;
      state.areasLoading = false;
      console.log("main/getAreasByGeolocation error in reducer");
      console.log(action);
    });

    /** getAreaDetails */
    builder.addCase(getAreaDetails.pending, (state, action) => {

    });
    builder.addCase(getAreaDetails.fulfilled, (state, action) => {
      state.selectedArea = {...state.selectedArea, ...action.payload};
    });
    builder.addCase(getAreaDetails.rejected, (state, action) => {
      console.log("main/getAreaDetails error in reducer");
      console.log(action);
    });
  }
})

/** Action creators are generated for each case reducer function */
export const { 
  resetPricings,
  setSelectedArea, 
  setLastVisitedLocation
} = mainSlice.actions;

/** Async actions */
export {
  getAreaDetails,
  getAreasByGeolocation
};

export default mainSlice.reducer;
