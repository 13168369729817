import { createAsyncThunk } from '@reduxjs/toolkit';

/** Endpoints */
import SendReceipt from '@endpoints/Email/SendReceipt';

const sendReceiptToEmail = createAsyncThunk(
  'parkingTicket/sendReceiptToEmail',
  async ({ to, sessionTag }) => {
    const body = {
      to,
      sessionTag
    };

    try {
      let result = await SendReceipt.post(null, body);
      return result;

    } catch (error) {
      console.log("Error parkingTicket/sendReceiptToEmail");
      console.log(error);
    }
  }
);

export default sendReceiptToEmail;
