// Supported languages  
export const supportedLanguages = ['en', 'sv'];

const i18Config = selectedLanguage => {    
  // Build resources object
  const resources = {};
  supportedLanguages.forEach(lan => resources[lan] = { translation: require(`./${lan}.json`) })
  
  return {    
    resources,
    lng: selectedLanguage,
    fallbackLng: supportedLanguages[0],
    interpolation: {
      escapeValue: false
    }
  }
}

export default i18Config;